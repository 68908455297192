import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ImageBackground = ({ styles = {} }) => {
	// Start with blank imageSrc, not prop.src because we never want to load
	//   the unoptimized image
	const baseStyle = {
		zIndex: -1,
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	};

	return (
		<div className="image-background" style={{ ...styles, ...baseStyle }} />
	);
};

ImageBackground.propTypes = {
	styles: PropTypes.shape({})
};

function areEqual(prevProps, nextProps) {
	return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default memo(ImageBackground, areEqual);
