import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'wsm-skeleton';
import { setClassNames } from 'ddc-classnames-js';
import { aR as ASPECT_RATIO } from '../../global-constants';
import CustomAssetSkeleton from './CustomAssetSkeleton';
import DefaultImageSkeleton from './DefaultImageSkeleton';
import { fakeHeightOfCarousel } from '../../utilities/skeleton';
import './PromoSkeletonWrapper.scss';

const PromoSkeletonWrapper = (props) => {
	const {
		aspectRatio,
		enableCarousel,
		displayLimit,
		customAssets,
		imageUrl,
		windowId,
		filteredPromosLength,
		isServer
	} = props;
	// This happens on Mobile6x1 and Mobile4x3SlideShow
	let placeholderImage = '';
	const extraBtnHeight =
		(aspectRatio === ASPECT_RATIO.MOBILE_6X1 ||
			aspectRatio === ASPECT_RATIO.MOBILE_4X3_SLIDESHOW) &&
		filteredPromosLength > 2;

	if (customAssets) {
		placeholderImage = customAssets;
	} else if (aspectRatio !== ASPECT_RATIO.MOBILE_6X1) {
		placeholderImage = imageUrl;
	}
	const classes = [
		aspectRatio === ASPECT_RATIO.MOBILE_4X3_SLIDESHOW &&
		enableCarousel === 'false'
			? 'mb-4'
			: ''
	];

	const hasIndicator =
		[
			ASPECT_RATIO.MOBILE_4X3_SLIDESHOW,
			ASPECT_RATIO.DESKTOP_10X1,
			ASPECT_RATIO.MOBILE_6X1
		].includes(aspectRatio) && filteredPromosLength > 2;

	// Create Indicator Skeleton
	const IndicatorSkeleton = () => {
		// This Indicator skeleton happens on DESKTOP_10X1, MOBILE_6X1 and MOBILE_4X3_SLIDESHOW
		return (
			enableCarousel === 'true' &&
			displayLimit !== '1' && (
				<div
					className={`align-items-center justify-content-between indicator-placeholder ${
						hasIndicator ? 'd-flex' : 'd-none'
					}`}
					style={{
						height: `${
							aspectRatio === ASPECT_RATIO.DESKTOP_10X1
								? '50px'
								: '48px'
						}`
					}}
					id={`indicator-placeholder-${windowId}`}
				>
					<div className="d-flex justify-content-center align-items-center flex-grow-1">
						<Skeleton
							height="8px"
							width="8px"
							circle
							skeletonClasses="mx-2"
						/>
						<Skeleton
							height="8px"
							width="8px"
							circle
							skeletonClasses="mx-2"
						/>
						<Skeleton
							height="8px"
							width="8px"
							circle
							skeletonClasses="mx-2"
						/>
						<Skeleton
							height="8px"
							width="8px"
							circle
							skeletonClasses="mx-2"
						/>
					</div>
					{aspectRatio === ASPECT_RATIO.DESKTOP_10X1 ? (
						<>
							<Skeleton
								height="35px"
								width="35px"
								circle
								skeletonClasses="mx-4"
							/>
							<Skeleton
								height="35px"
								width="35px"
								circle
								skeletonClasses="mx-3 ml-4"
							/>
							<Skeleton
								height="35px"
								width="35px"
								circle
								skeletonClasses="ml-3 mr-6"
							/>
						</>
					) : (
						<Skeleton
							height={`${
								aspectRatio === ASPECT_RATIO.MOBILE_6X1
									? '28px'
									: '30px'
							}`}
							width={`${
								aspectRatio === ASPECT_RATIO.MOBILE_6X1
									? '28px'
									: '30px'
							}`}
							circle
							skeletonClasses="m-4"
						/>
					)}
				</div>
			)
		);
	};

	return (
		<>
			<div
				id="top-skeleton-wrapper"
				style={{
					height: extraBtnHeight
						? fakeHeightOfCarousel(
								enableCarousel,
								displayLimit,
								aspectRatio
						  )
						: null
				}}
				className={setClassNames(classes)}
			>
				<div
					id={`spe-custom-asset-wrapper-${windowId}`}
					className={`${customAssets ? 'd-block' : 'd-none'}`}
				>
					<CustomAssetSkeleton
						{...props}
						placeholderImage={placeholderImage}
					/>
					{/* End Custom Asset Skeleton Section for banner and slideshow */}
					{/* Extra height of Indicator and not happen on Desktop4x1, Desktop4x3 and Mobile4x3Coupon */}
					{(isServer || hasIndicator) && <IndicatorSkeleton />}
				</div>

				<div
					id={`spe-default-asset-wrapper-${windowId}`}
					className={`${
						!customAssets && imageUrl ? 'd-block' : 'd-none'
					}`}
				>
					<DefaultImageSkeleton
						{...props}
						placeholderImage={placeholderImage}
					/>
					{/* End Default Image Skeleton Section for banner and slideshow */}
					{/* Extra height of Indicator and not happen on Desktop4x1, Desktop4x3 and Mobile4x3Coupon */}
					{(isServer || hasIndicator) && <IndicatorSkeleton />}
				</div>
			</div>
		</>
	);
};

PromoSkeletonWrapper.propTypes = {
	aspectRatio: PropTypes.string,
	enableCarousel: PropTypes.string,
	displayLimit: PropTypes.string,
	customAssets: PropTypes.string,
	imageUrl: PropTypes.string,
	ratio: PropTypes.string,
	windowId: PropTypes.string,
	filteredPromosLength: PropTypes.number,
	isServer: PropTypes.bool
};

export default PromoSkeletonWrapper;
