import React from 'react';
import { useLabels, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { templatePropTypes } from '../../utilities/commonPropTypes';
import OptimizedImageBackground from '../../components/OptimizedImageBackground';
import AspectRatioBox from '../../components/aspect-ratio-box/AspectRatioBox';
import {
	ctaButtonFragment,
	detailsButtonFragment,
	offerDetails10x1Fragment,
	ctaPrintFragment
} from '../commonFragments';
import {
	getVehicleImages,
	getIncentiveImage,
	getNoImageLabels,
	getFormattedDollarSign
} from '../../utilities/general.jsx';
import { g } from '../../global-constants';

const Desktop10x1 = ({
	applicableVehicle = '',
	promoIndex,
	couponCode = '',
	ctaButtonData,
	description,
	discountDisplay = '',
	imageUrl,
	incentive,
	isMobile,
	offerDetails,
	promo,
	title,
	type,
	vehicleImages = [],
	width,
	triggerPrint,
	vehicleBackgroundImage,
	modelOfferBackgroundImage
}) => {
	let template;
	const labels = useLabels();
	const { reduceDollarSignSize } = usePrefs();
	const ctaButtonClasses = setClassNames(['btn-inverse']);
	const ctaModalButtonClasses = setClassNames(['my-4']);

	const detailsButtonClasses = [
		'pr-0',
		'text-decoration-underline',
		'text-transform-none'
	];

	const displayTextClasses = setClassNames([
		'd-flex',
		'flex-column',
		'justify-content-center',
		'flex-shrink-0'
	]);

	const displayClasses = setClassNames([
		'flex-1',
		'd-flex',
		'flex-row',
		'justify-content-center',
		'flex-shrink-0'
	]);

	const noImageLabels = getNoImageLabels(labels, promo.inventoryCount);

	if ([g.PARTS, g.SERVICE, g.EVENT].includes(type)) {
		template = (
			<div className="d-flex flex-row w-100 bg-neutral-1000">
				<OptimizedImageBackground
					key={promo.id}
					className="w-100 flex-shrink-0 image"
					style={{
						backgroundImage: `url("${imageUrl}")`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						backgroundColor: 'black',
						flex: 1
					}}
					width={width / 4}
				/>
				<div
					className={setClassNames([displayTextClasses, 'text'])}
					style={{
						flex: 2
					}}
				>
					{title ? (
						<h2 tabIndex={promoIndex + 1}>
							{getFormattedDollarSign(
								title,
								reduceDollarSignSize
							)}
						</h2>
					) : null}
					{description ? (
						<p tabIndex={promoIndex + 2}>
							{getFormattedDollarSign(
								description,
								reduceDollarSignSize
							)}
						</p>
					) : null}
				</div>
				<div
					className={setClassNames([
						displayTextClasses,
						'text-center'
					])}
					style={{
						flex: 0.6
					}}
				>
					{discountDisplay && (
						<div
							className="font-weight-bold text-capitalize discount"
							tabIndex={promoIndex + 3}
						>
							{discountDisplay}
						</div>
					)}
				</div>
				<div
					className={setClassNames([
						displayTextClasses,
						'text-right',
						'align-items-end',
						'mr-3',
						'bg-neutral-1000'
					])}
					style={{
						flex: 1
					}}
				>
					{offerDetails10x1Fragment({
						couponCode,
						promoIndex,
						cta: ctaButtonFragment({
							promoIndex,
							ctaButtonClasses,
							ctaButtonData,
							promo
						}),
						details: detailsButtonFragment({
							promoIndex,
							detailsButtonClasses,
							promo,
							cta: ctaButtonFragment({
								promoIndex,
								ctaModalButtonClasses,
								ctaButtonData,
								promo
							}),
							ctaPrint: ctaPrintFragment({
								promo,
								pushOutsiderCtaPrint: triggerPrint,
							}),	
						}),
						labels
					})}
				</div>
			</div>
		);
	}

	if (type && type === g.VEHICLE) {
		template = (
			<OptimizedImageBackground
				className="d-flex flex-row"
				style={{
					backgroundImage: `url("${vehicleBackgroundImage}")`,
					backgroundPosition: 'center 60%',
					backgroundSize: 'cover',
					flexBasis: `${width}px`
				}}
				width={width}
			>
				<div
					key={promo.id}
					className={displayClasses}
					style={{
						flex: 1,
						alignItems: 'center',
						paddingLeft: '1%',
						paddingRight: '1%'
					}}
				>
					{getVehicleImages(vehicleImages, width / 2, isMobile, 0, {
						title: noImageLabels.title,
						subtitle: noImageLabels.subtitle,
						aspectRatio: 'desktop10x1'
					})}
				</div>
				<div
					className={setClassNames([
						displayTextClasses,
						'px-5',
						'bg-neutral-1000'
					])}
					style={{
						flex: 2
					}}
				>
					{title ? (
						<h2 tabIndex={promoIndex + 1}>
							{getFormattedDollarSign(
								title,
								reduceDollarSignSize
							)}
							<span className="sr-only">
								{getFormattedDollarSign(
									title,
									reduceDollarSignSize
								)}
							</span>
						</h2>
					) : null}
				</div>
				<div
					className={setClassNames([
						displayTextClasses,
						'text-right',
						'align-items-end',
						'pr-3',
						'bg-neutral-1000'
					])}
					style={{
						flex: 1
					}}
				>
					{offerDetails10x1Fragment({
						couponCode,
						cta: ctaButtonFragment({
							promoIndex,
							ctaButtonClasses,
							ctaButtonData,
							promo
						}),
						details: detailsButtonFragment({
							promoIndex,
							detailsButtonClasses,
							promo,
							cta: ctaButtonFragment({
								promoIndex,
								ctaModalButtonClasses,
								ctaButtonData,
								promo
							}),
							ctaPrint: ctaPrintFragment({
								promo,
								pushOutsiderCtaPrint: triggerPrint,
							}),
						}),
						labels
					})}
				</div>
			</OptimizedImageBackground>
		);
	}

	if (type && type === g.INCENTIVE) {
		template = (
			<OptimizedImageBackground
				className="d-flex flex-row"
				style={{
					backgroundImage: `url("${modelOfferBackgroundImage}")`,
					backgroundPosition: 'bottom',
					backgroundSize: 'cover',
					flexBasis: `${width}px`
				}}
				width={width}
			>
				<div
					key={promo.id}
					className={displayClasses}
					style={{
						flex: 1,
						alignItems: 'center'
					}}
				>
					{getIncentiveImage(
						imageUrl,
						width / 2,
						'', // leave empty unless we have a label other than YMMT
						isMobile,
						65,
						{
							title: noImageLabels.title,
							subtitle: noImageLabels.subtitle,
							aspectRatio: 'desktop10x1'
						}
					)}
				</div>
				<div
					className={setClassNames([
						displayTextClasses,
						'px-5',
						'bg-neutral-1000'
					])}
					style={{
						flex: 2
					}}
				>
					{applicableVehicle ? (
						<h2 tabIndex={promoIndex + 1}>{applicableVehicle}</h2>
					) : null}
					{title ? (
						<p tabIndex={promoIndex + 2}>
							{getFormattedDollarSign(
								title,
								reduceDollarSignSize
							)}
						</p>
					) : null}
					{offerDetails ? (
						<p tabIndex={promoIndex + 3}>
							{getFormattedDollarSign(
								offerDetails,
								reduceDollarSignSize
							)}
						</p>
					) : null}
				</div>
				<div
					className={setClassNames([
						displayTextClasses,
						'text-right',
						'align-items-end',
						'pr-3',
						'cta-wrapper',
						'bg-neutral-1000'
					])}
					style={{
						flex: 1
					}}
				>
					{ctaButtonFragment({
						promoIndex,
						ctaButtonClasses,
						ctaButtonData,
						promo
					})}
					{detailsButtonFragment({
						promoIndex,
						detailsButtonClasses,
						incentive,
						promo,
						cta: ctaButtonFragment({
							promoIndex,
							ctaModalButtonClasses,
							ctaButtonData,
							promo
						}),
						ctaPrint: ctaPrintFragment({
							promo,
							pushOutsiderCtaPrint: triggerPrint,
						}),	
					})}
				</div>
			</OptimizedImageBackground>
		);
	}

	return <AspectRatioBox>{template}</AspectRatioBox>;
};

Desktop10x1.propTypes = templatePropTypes;

export default Desktop10x1;
