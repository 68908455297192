import React from 'react';
import { useLabels, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { templatePropTypes } from '../../utilities/commonPropTypes';
import AspectRatioBox from '../../components/aspect-ratio-box/AspectRatioBox';
import OptimizedImageBackground from '../../components/OptimizedImageBackground';
import {
	ctaButtonFragment,
	detailsButtonFragment,
	offerDetailsFragment,
	ctaPrintFragment
} from '../commonFragments';
import {
	getVehicleImages,
	getIncentiveImage,
	getNoImageLabels,
	getFormattedDollarSign
} from '../../utilities/general.jsx';
import { g } from '../../global-constants';

const Desktop4x1 = ({
	applicableVehicle = '',
	promoIndex,
	couponCode = '',
	ctaButtonData,
	description,
	discountDisplay = '',
	imageUrl,
	incentive,
	isMobile,
	offerDetails,
	promo,
	title,
	type,
	vehicleImages = [],
	width,
	triggerPrint,
	vehicleBackgroundImage,
	modelOfferBackgroundImage
}) => {
	let template;
	const labels = useLabels();
	const { reduceDollarSignSize } = usePrefs();
	const ctaButtonClasses = setClassNames(['btn-inverse', 'd-table', 'mb-3']);
	const ctaModalButtonClasses = setClassNames(['my-4']);

	const detailsButtonClasses = [
		'pl-0',
		'text-decoration-underline',
		'text-transform-none'
	];

	const noImageLabels = getNoImageLabels(labels, promo.inventoryCount);
	const gradient =
		'linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 40%, rgba(0, 0, 0, 0.70) 50%, rgba(0, 0, 0, 0.70) 100%)';

	if ([g.PARTS, g.SERVICE, g.EVENT].includes(type)) {
		template = (
			<div className="d-flex flex-row">
				<OptimizedImageBackground
					key={promo.id}
					className="flex-1 w-100 flex-shrink-0 image"
					style={{
						backgroundImage: `url("${imageUrl}")`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						flexBasis: `${width / 2}px`,
						backgroundColor: 'black'
					}}
					width={width / 2}
				/>
				<div
					className={setClassNames([
						'flex-1',
						'd-flex',
						'flex-column',
						'justify-content-center',
						'flex-shrink-0',
						'align-middle',
						'text'
					])}
					style={{
						flexBasis: `${width / 2}px`,
						paddingRight: '5%',
						paddingLeft: '3%'
					}}
				>
					{offerDetailsFragment({
						couponCode,
						cta: ctaButtonFragment({
							promoIndex,
							ctaButtonClasses,
							ctaButtonData,
							promo
						}),
						description: getFormattedDollarSign(
							description,
							reduceDollarSignSize
						),
						details: detailsButtonFragment({
							promoIndex,
							detailsButtonClasses,
							promo,
							cta: ctaButtonFragment({
								promoIndex,
								ctaModalButtonClasses,
								ctaButtonData,
								promo
							}),
							ctaPrint: ctaPrintFragment({
								promo,
								pushOutsiderCtaPrint: triggerPrint,
							}),		
						}),
						discountDisplay,
						labels,
						promoIndex,
						title: getFormattedDollarSign(
							title,
							reduceDollarSignSize
						)
					})}
				</div>
			</div>
		);
	}

	if (type && type === g.VEHICLE) {
		template = (
			<OptimizedImageBackground
				className="d-flex flex-row"
				style={{
					backgroundImage: `${gradient},url("${vehicleBackgroundImage}")`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					flexBasis: `${width}px`
				}}
				width={width}
			>
				<div
					key={promo.id}
					className={setClassNames([
						'flex-1',
						'd-flex',
						'flex-row',
						'justify-content-center',
						'flex-shrink-0',
						'align-middle'
					])}
					style={{
						flexBasis: `${width / 2}px`,
						alignItems: 'center',
						paddingLeft: '1%',
						paddingRight: '1%'
					}}
				>
					{getVehicleImages(vehicleImages, width / 2, isMobile, 0, {
						title: noImageLabels.title,
						subtitle: noImageLabels.subtitle
					})}
				</div>
				<div
					className={setClassNames([
						'flex-1',
						'd-flex',
						'flex-column',
						'justify-content-center',
						'flex-shrink-0',
						'align-middle'
					])}
					style={{
						flexBasis: `${width / 2}px`,
						paddingRight: '5%',
						paddingLeft: '3%'
					}}
				>
					{offerDetailsFragment({
						couponCode,
						cta: ctaButtonFragment({
							promoIndex,
							ctaButtonClasses,
							ctaButtonData,
							promo
						}),
						description: getFormattedDollarSign(
							description,
							reduceDollarSignSize
						),
						details: detailsButtonFragment({
							promoIndex,
							detailsButtonClasses,
							promo,
							cta: ctaButtonFragment({
								promoIndex,
								ctaModalButtonClasses,
								ctaButtonData,
								promo
							}),
							ctaPrint: ctaPrintFragment({
								promo,
								pushOutsiderCtaPrint: triggerPrint,
							}),
						}),
						discountDisplay,
						labels,
						promoIndex,
						title: getFormattedDollarSign(
							title,
							reduceDollarSignSize
						)
					})}
				</div>
			</OptimizedImageBackground>
		);
	}

	if (type && type === g.INCENTIVE) {
		template = (
			<OptimizedImageBackground
				key={promo.id}
				className="d-flex flex-row"
				style={{
					backgroundImage: `${gradient},url("${modelOfferBackgroundImage}")`,
					backgroundPosition: 'bottom',
					backgroundSize: 'cover',
					flexBasis: `${width}px`
				}}
				width={width}
			>
				<div
					key={promo.id}
					className="flex-1 d-flex flex-row justify-content-center flex-shrink-0 align-middle"
					style={{
						flexBasis: `${width / 2}px`,
						alignItems: 'center'
					}}
				>
					{getIncentiveImage(
						imageUrl,
						width / 2,
						'', // leave empty unless we have a label other than YMMT
						isMobile,
						65,
						{
							title: noImageLabels.title,
							subtitle: noImageLabels.subtitle
						}
					)}
				</div>
				<div
					className="flex-1 d-flex flex-column justify-content-center flex-shrink-0 align-middle"
					style={{
						flexBasis: `${width / 2}px`,
						paddingRight: '5%',
						paddingLeft: '3%'
					}}
				>
					{applicableVehicle || title ? (
						<h2 tabIndex={promoIndex + 1}>
							<div className="pb-3">{applicableVehicle}</div>
							<div className="font-weight-normal">
								{getFormattedDollarSign(
									title,
									reduceDollarSignSize
								)}
							</div>
						</h2>
					) : null}
					{offerDetails ? (
						<p tabIndex={promoIndex + 2}>
							{getFormattedDollarSign(
								offerDetails,
								reduceDollarSignSize
							)}
						</p>
					) : null}
					<div className="cta-wrapper">
						{ctaButtonFragment({
							promoIndex,
							ctaButtonClasses,
							ctaButtonData,
							promo
						})}
						{detailsButtonFragment({
							promoIndex,
							detailsButtonClasses,
							incentive,
							promo,
							cta: ctaButtonFragment({
								promoIndex,
								ctaModalButtonClasses,
								ctaButtonData,
								promo
							}),
							ctaPrint: ctaPrintFragment({
								promo,
								pushOutsiderCtaPrint: triggerPrint,
							}),
						})}
					</div>
				</div>
			</OptimizedImageBackground>
		);
	}

	return <AspectRatioBox>{template}</AspectRatioBox>;
};

Desktop4x1.propTypes = templatePropTypes;

export default Desktop4x1;
