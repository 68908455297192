import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useLabels } from 'wsm-common-data';

const LoadMoreButton = ({ onClick, showMoreBtn, promosLength }) => {
	const labels = useLabels();
	let button;
	const tabIndexNumber = promosLength * 10 + 1;

	if (showMoreBtn) {
		button = (
			<Button
				tabIndex={tabIndexNumber}
				className="d-block w-100 load-more"
				onClick={() => onClick()}
				bsStyle="primary"
				bsSize="lg"
				aria-label="Load More"
			>
				{labels.get('LOAD_MORE')}
				<i className="ddc-icon ddc-icon-arrow1-down text-light pl-3" />
			</Button>
		);
	} else {
		button = null;
	}

	return button;
};

LoadMoreButton.propTypes = {
	promosLength: PropTypes.number,
	onClick: PropTypes.func.isRequired,
	showMoreBtn: PropTypes.bool.isRequired
};

export default LoadMoreButton;
