import { a } from '../global-constants';

const positionData = (state = {}, { type, payload }) => {
	switch (type) {
		case a.SET_POSITION_DATA: {
			return {
				...state,
				...payload
			};
		}
		default:
			return state;
	}
};

export default positionData;
