import React, { useEffect, useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { optimizeImage } from '../utilities/images';
import { trackPerformanceToNR } from '../utilities/tracking';

const OptimizedImage = ({ alt, className = null, src, style = {}, width }) => {
	// Start with blank imageSrc, not prop.src because we never want to load
	//   the unoptimized image
	const [currentWidth, setCurrentWidth] = useState(0);

	useEffect(() => {
		trackPerformanceToNR('ws-specials-image-container-starting');
	}, []);

	const imageSrc = useMemo(() => {
		// Only load larger images if the width changes...never downgrade
		const optimized = optimizeImage(
			src,
			width > currentWidth ? width : currentWidth
		);
		setCurrentWidth(optimized.width);
		return optimized.src;
	}, [src, width, currentWidth]);

	return (
		<img // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
			className={className}
			style={style}
			alt={alt}
			onLoad={() => {
				trackPerformanceToNR('ws-specials-first-promo-img-load');
			}}
			src={imageSrc}
		/>
	);
};

OptimizedImage.propTypes = {
	alt: PropTypes.string.isRequired,
	className: PropTypes.string,
	src: PropTypes.string.isRequired,
	style: PropTypes.shape({}),
	width: PropTypes.number.isRequired
};

function areEqual(prevProps, nextProps) {
	return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default memo(OptimizedImage, areEqual);
