import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { usePrefs, useRequestData } from 'ws-scripts/modules/common';
import DeferredComponent from '../ws-scripts/components/DeferredComponent';
import Promo from './Promo/Promo';
import { useIntersectionObserver } from '../hooks/useIntersectionObeserver';
import { trackImpression, trackMultiImpression } from '../utilities/tracking';
import { groupObjArrByKey } from '../utilities/utility';
import {
	filterVehiclePromosByYmmt,
	reduceImageSizeFromPromo
} from '../utilities/promos';
import LoadMoreButton from './LoadMoreButton';
import { useURLPrefs } from '../hooks/useURLPrefs';
import { optimizeDefaultDownsize } from '../utilities/images';

const Carousel = React.lazy(() => import('./Slick/Carousel'));

const TemplateWrapper = ({
	promos,
	aspectRatio,
	isMobile,
	width,
	isCoupon,
	filterParams,
	csrPlaceholder
}) => {
	const DEFAULT_AUTOPLAY_SPEED = 5000;
	const desktopCards = 9;
	const desktopCardsSM = 6;
	const mobileCards = 4;
	const cardsToLoad = isMobile ? mobileCards : desktopCards;
	const { widgetName, windowId, deviceType } = useRequestData();
	let filteredPromos = filterVehiclePromosByYmmt(promos, filterParams);

	const { autoplay, autoplaySpeed, showLoadMoreButton } = usePrefs();
	let { vehicleBackgroundImage, modelOfferBackgroundImage } = usePrefs();
	if (!vehicleBackgroundImage.startsWith('https')) {
		vehicleBackgroundImage = vehicleBackgroundImage.replace(
			'http',
			'https'
		);
	}
	if (!modelOfferBackgroundImage.startsWith('https')) {
		modelOfferBackgroundImage = modelOfferBackgroundImage.replace(
			'http',
			'https'
		);
	}
	const vehicleBackgroundImageObj = optimizeDefaultDownsize(
		vehicleBackgroundImage,
		deviceType,
		aspectRatio
	);
	const modelOfferBackgroundImageObj = optimizeDefaultDownsize(
		modelOfferBackgroundImage,
		deviceType,
		aspectRatio
	);
	const { enableCarousel } = useURLPrefs();
	const personalized = useSelector((state) => state.personalized);
	const hasdata = useSelector((state) => state.hasdata);
	const [promoOutsiderButton, setPromoOutsiderButton] = useState(null);
	const [singleContainerRef] = useIntersectionObserver(0.99, () => {
		if (hasdata) {
			trackImpression({
				widgetName,
				windowId,
				aspectRatio,
				promo: filteredPromos[0],
				index: 0,
				personalized
			});
		}
	});
	const [couponContainerRef] = useIntersectionObserver(0, () => {
		if (hasdata) {
			const promosToLoad = filteredPromos.slice(0, cardsToLoad);
			const promosGroupByTypes = groupObjArrByKey(promosToLoad, 'type');
			Object.keys(promosGroupByTypes).forEach((v) => {
				trackMultiImpression({
					widgetName,
					windowId,
					aspectRatio,
					promos: promosGroupByTypes[v],
					personalized
				});
			});
		}
	});
	let renderedSlideshow;
	const isCouponCaro = enableCarousel === 'true' && isCoupon && !isMobile;

	// This section is a Load More button for the coupon listing placement with carousel false
	const sliceCards = (toSlice) => {
		return filteredPromos.slice(0, toSlice);
	};

	const [showMoreBtn, setShowMoreBtn] = useState(
		showLoadMoreButton === 'true' && filteredPromos.length > cardsToLoad
	);
	const [cards, loadCards] = useState(
		showMoreBtn ? sliceCards(cardsToLoad) : filteredPromos
	);

	filteredPromos = reduceImageSizeFromPromo(
		filteredPromos,
		width,
		aspectRatio
	);
	const cardsWithOptimizedImages = reduceImageSizeFromPromo(
		cards,
		width,
		aspectRatio
	);

	useEffect(() => {
		if (!isMobile && showMoreBtn) {
			if (width + 48 < 992) {
				loadCards(sliceCards(desktopCardsSM));
			} else {
				loadCards(sliceCards(desktopCards));
			}
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [width]);

	const carouselComp = (
		<DeferredComponent placeholder={csrPlaceholder}>
			<Carousel
				aspectRatio={aspectRatio}
				isMobile={isMobile}
				promos={filteredPromos}
				slickProps={{
					autoplay: autoplay === 'true',
					autoplaySpeed:
						parseInt(autoplaySpeed, 10) || DEFAULT_AUTOPLAY_SPEED
				}}
				width={width}
				isCouponCaro={isCouponCaro}
				vehicleBackgroundImage={vehicleBackgroundImageObj.src}
				modelOfferBackgroundImage={modelOfferBackgroundImageObj.src}
			/>
		</DeferredComponent>
	);

	if (filteredPromos.length === 1) {
		// Display single image without carousel
		const promo = (
			<Promo
				aspectRatio={aspectRatio}
				promoIndex={0}
				isMobile={isMobile}
				isVisible
				isCurrent
				noNeedAriaCurrent
				promo={filteredPromos[0]}
				width={width}
				onPromoPushOutsideButton={(btn) => {
					setPromoOutsiderButton(btn);
				}}
				vehicleBackgroundImage={vehicleBackgroundImageObj.src}
				modelOfferBackgroundImage={modelOfferBackgroundImageObj.src}
			/>
		);

		// Promo may not render (e.g. an incentive promo with no matching incentive)
		if (promo) {
			renderedSlideshow = (
				// Wrap with markup similar to what's added by react-slick
				<div
					className="single-promo-container"
					ref={singleContainerRef}
					data-testid="single-container"
				>
					<div className="single-promo">
						<div>{promo}</div>
						{promoOutsiderButton}
					</div>
				</div>
			);
		}
	} else if (isCoupon) {
		// For Desktop - render slick when pref is enabled and there's more than 2 promos (screen dragging)
		// For Mobile - render slick when pref is enabled
		if (
			(isCouponCaro && filteredPromos.length > 2) ||
			(enableCarousel === 'true' && isMobile)
		) {
			renderedSlideshow = carouselComp;
		} else {
			renderedSlideshow = (
				// Wrap with markup similar to what's added by react-slick
				// this is grid
				<div
					className="coupon-container d-flex flex-wrap"
					ref={couponContainerRef}
				>
					{cardsWithOptimizedImages?.map((promo, i) => (
						<Promo
							aspectRatio={aspectRatio}
							promoIndex={i}
							isMobile={isMobile}
							isVisible
							noNeedAriaCurrent
							promo={promo}
							width={width}
							onPromoPushOutsideButton={(btn) =>
								setPromoOutsiderButton(btn)
							}
							key={promo.id}
							vehicleBackgroundImage={
								vehicleBackgroundImageObj.src
							}
							modelOfferBackgroundImage={
								modelOfferBackgroundImageObj.src
							}
						/>
					))}
					<LoadMoreButton
						promosLength={cardsWithOptimizedImages.length}
						onClick={() => {
							loadCards(filteredPromos);
							setShowMoreBtn(false);
							const promosToLoadMore =
								filteredPromos.slice(cardsToLoad);
							const promosGroupByTypesMore = groupObjArrByKey(
								promosToLoadMore,
								'type'
							);
							Object.keys(promosGroupByTypesMore).forEach((v) => {
								trackMultiImpression({
									widgetName,
									windowId,
									aspectRatio,
									promos: promosGroupByTypesMore[v],
									personalized
								});
							});
						}}
						showMoreBtn={showMoreBtn}
					/>
				</div>
			);
		}
	} else {
		renderedSlideshow = carouselComp;
	}

	return renderedSlideshow;
};

TemplateWrapper.propTypes = {
	csrPlaceholder: PropTypes.node,
	promos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	aspectRatio: PropTypes.string.isRequired,
	isMobile: PropTypes.bool.isRequired,
	width: PropTypes.number.isRequired,
	filterParams: PropTypes.shape({})
};

export default TemplateWrapper;
