import React from 'react';
import { useLabels, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { templatePropTypes } from '../../utilities/commonPropTypes';
import AspectRatioBox from '../../components/aspect-ratio-box/AspectRatioBox';
import OptimizedImageBackground from '../../components/OptimizedImageBackground';
import {
	ctaButtonFragment,
	detailsButtonFragment,
	offerDetails10x1Fragment,
	ctaPrintFragment
} from '../commonFragments';
import {
	getVehicleImages,
	getIncentiveImage,
	getNoImageLabels,
	getFormattedDollarSign
} from '../../utilities/general.jsx';
import { g } from '../../global-constants';

const Desktop10x1 = ({
	applicableVehicle = '',
	promoIndex,
	couponCode = '',
	ctaButtonData,
	description,
	discountDisplay = '',
	imageUrl,
	incentive,
	isMobile,
	offerDetails,
	promo,
	title,
	type,
	vehicleImages = [],
	width,
	triggerPrint,
	vehicleBackgroundImage,
	modelOfferBackgroundImage
}) => {
	let template;
	const labels = useLabels();
	const { reduceDollarSignSize } = usePrefs();
	const ctaButtonClasses = setClassNames(['btn-inverse cta-btn']);
	const ctaModalButtonClasses = setClassNames(['my-4']);

	const detailsButtonClasses = [
		'pr-0',
		'text-decoration-underline',
		'text-transform-none'
	];

	const displayTextClasses = setClassNames([
		'd-flex',
		'flex-column',
		'justify-content-center',
		'flex-shrink-0'
	]);

	const displayClasses = setClassNames([
		'flex-1',
		'd-flex',
		'flex-row',
		'justify-content-center',
		'flex-shrink-0'
	]);

	const noImageLabels = getNoImageLabels(labels, promo.inventoryCount);

	if ([g.PARTS, g.SERVICE, g.EVENT].includes(type)) {
		template = (
			<div className="wrapper d-flex flex-1">
				<div className=" text-wrapper d-flex ">
					<div className=" d-flex flex-row flex-basis">
						<div
							className={setClassNames([
								displayTextClasses,
								'text'
							])}
							style={{
								flex: 2
							}}
						>
							<h2>
								{getFormattedDollarSign(
									title,
									reduceDollarSignSize
								)}
							</h2>
							<p>
								{getFormattedDollarSign(
									description,
									reduceDollarSignSize
								)}
							</p>
						</div>
						<div
							className={setClassNames([
								displayTextClasses,
								'text-center'
							])}
							style={{
								flex: 0.5
							}}
						>
							{discountDisplay && (
								<div className="font-weight-bold text-capitalize discount">
									{discountDisplay}
								</div>
							)}
						</div>
						<div
							className={setClassNames([
								displayTextClasses,
								'text-right',
								'align-items-end',
								'mr-3'
							])}
							style={{
								flex: 1
							}}
						>
							{offerDetails10x1Fragment({
								couponCode,
								cta: ctaButtonFragment({
									promoIndex,
									ctaButtonClasses,
									ctaButtonData,
									promo
								}),
								details: detailsButtonFragment({
									promoIndex,
									detailsButtonClasses,
									promo,
									cta: ctaButtonFragment({
										promoIndex,
										ctaModalButtonClasses,
										ctaButtonData,
										promo
									}),
									ctaPrint: ctaPrintFragment({
										promo,
										pushOutsiderCtaPrint: triggerPrint,
									}),
								}),
								labels
							})}
						</div>
					</div>
				</div>
				<div className="stripe" />
				<OptimizedImageBackground
					key={promo.id}
					className="flex-shrink-0 image"
					style={{
						backgroundImage: `url("${imageUrl}")`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						backgroundColor: '#063F7D',
						flex: 1
					}}
					width={width / 4}
				/>
			</div>
		);
	}

	if (type && type === g.VEHICLE) {
		template = (
			<OptimizedImageBackground
				className="d-flex flex-row"
				style={{
					backgroundImage: `url("${vehicleBackgroundImage}")`,
					backgroundPosition: '500px 60%',
					backgroundSize: 'cover',
					flexBasis: `${width}px`
				}}
				width={width}
			>
				<div className="wrapper d-flex flex-1">
					<div className="text-wrapper d-flex">
						<div className="d-flex flex-row flex-basis">
							<div
								className={setClassNames([
									displayTextClasses,
									'text'
								])}
								style={{
									flex: 2
								}}
							>
								<h2>
									{getFormattedDollarSign(
										title,
										reduceDollarSignSize
									)}
								</h2>
								<p>
									{getFormattedDollarSign(
										description,
										reduceDollarSignSize
									)}
								</p>
							</div>
							<div
								className={setClassNames([
									displayTextClasses,
									'text-right',
									'align-items-end',
									'pr-3'
								])}
								style={{
									flex: 1
								}}
							>
								{offerDetails10x1Fragment({
									couponCode,
									cta: ctaButtonFragment({
										promoIndex,
										ctaButtonClasses,
										ctaButtonData,
										promo
									}),
									details: detailsButtonFragment({
										promoIndex,
										detailsButtonClasses,
										promo,
										cta: ctaButtonFragment({
											promoIndex,
											ctaModalButtonClasses,
											ctaButtonData,
											promo
										}),
										ctaPrint: ctaPrintFragment({
											promo,
											pushOutsiderCtaPrint: triggerPrint,
										}),
									}),
									labels
								})}
							</div>
						</div>
					</div>
					<div className="stripe" />
					<div
						key={promo.id}
						className={setClassNames([
							'vehicles',
							'align-items-center',
							'd-flex',
							'flex-row',
							'justify-content-center'
						])}
					>
						{getVehicleImages(vehicleImages, width / 2, 0, {
							title: noImageLabels.title,
							subtitle: noImageLabels.subtitle,
							aspectRatio: 'desktop10x1'
						})}
					</div>
				</div>
			</OptimizedImageBackground>
		);
	}

	if (type && type === g.INCENTIVE) {
		template = (
			<OptimizedImageBackground
				className="d-flex flex-row"
				style={{
					backgroundImage: `url("${modelOfferBackgroundImage}")`,
					backgroundPosition: 'right 90%',
					backgroundSize: '40%',
					flexBasis: `${width}px`
				}}
				width={width}
			>
				<div className="wrapper d-flex flex-1">
					<div className="text-wrapper d-flex">
						<div className="d-flex flex-row flex-basis">
							<div
								className={setClassNames([
									displayTextClasses,
									'px-5'
								])}
								style={{
									flex: 2
								}}
							>
								<h2>{applicableVehicle}</h2>
								<p>
									{getFormattedDollarSign(
										title,
										reduceDollarSignSize
									)}
								</p>
								<p>
									{getFormattedDollarSign(
										offerDetails,
										reduceDollarSignSize
									)}
								</p>
							</div>
							<div
								className={setClassNames([
									displayTextClasses,
									'text-right',
									'align-items-end',
									'pr-3'
								])}
								style={{
									flex: 1
								}}
							>
								{ctaButtonFragment({
									promoIndex,
									ctaButtonClasses,
									ctaButtonData,
									promo
								})}
								{detailsButtonFragment({
									promoIndex,
									detailsButtonClasses,
									incentive,
									promo,
									cta: ctaButtonFragment({
										promoIndex,
										ctaModalButtonClasses,
										ctaButtonData,
										promo
									}),
									ctaPrint: ctaPrintFragment({
										promo,
										pushOutsiderCtaPrint: triggerPrint,
									}),
								})}
							</div>
						</div>
					</div>
					<div className="stripe" />
					<div
						key={promo.id}
						className={displayClasses}
						style={{
							flex: 1,
							alignItems: 'center'
						}}
					>
						{getIncentiveImage(
							imageUrl,
							width / 2,
							'', // leave empty unless we have a label other than YMMT
							isMobile,
							65,
							{
								title: noImageLabels.title,
								subtitle: noImageLabels.subtitle,
								aspectRatio: 'desktop10x1'
							}
						)}
					</div>
				</div>
			</OptimizedImageBackground>
		);
	}

	return <AspectRatioBox>{template}</AspectRatioBox>;
};

Desktop10x1.propTypes = templatePropTypes;

export default Desktop10x1;
