import { a } from '../global-constants';

const general = (state = {}, { type, payload }) => {
	switch (type) {
		case a.SET_LISTINGS_CAROUSEL_SLIDE_CONDITIONS: {
			return {
				...state,
				...payload
			};
		}
		default:
			return state;
	}
};

export default general;
