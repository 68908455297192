/* eslint-disable no-unused-vars, no-param-reassign, no-shadow */

import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { trackPerformanceToNR } from '../utilities/tracking';
import { optimizeImage } from '../utilities/images';
import ImageBackground from './ImageBackground';

const OptimizedImageBackground = ({
	children = null,
	className = null,
	style = {},
	width
}) => {
	// Start with blank image src, because we never want to load the
	//   unoptimized image
	const originalStyle = style;
	if (originalStyle.backgroundImage === 'url("undefined")') {
		delete originalStyle.backgroundImage;
	}

	const trackImageState = (message, src) => {
		const img = new Image();
		img.onload = () => trackPerformanceToNR(message);
		img.src = src;
	};

	const optimizedStyle = useMemo(() => {
		if (originalStyle && originalStyle.backgroundImage) {
			const regEx = /(.*)url\("(.+)"\)(.*)/;
			const background = regEx.exec(originalStyle.backgroundImage);
			if (background && background.length >= 4) {
				const [_original, pre, src, post] = background;
				trackImageState('ws-specials-image-container-starting', src);
				const optimizedImg = optimizeImage(src, width);
				if (optimizedImg.src && width > optimizedImg.width) {
					trackImageState(
						'ws-specials-image-container-starting',
						optimizedImg.src
					);
				}
				const optimizedStyles = {
					...originalStyle,
					position: 'relative',
					backgroundImage: optimizedImg.src
						? `${pre}url("${optimizedImg.src}")${post}`
						: `${pre}none${post}`
				};
				return optimizedStyles;
			}
		}

		return originalStyle;
	}, [originalStyle, width]);

	return (
		<div className={className} style={optimizedStyle}>
			<ImageBackground styles={optimizedStyle} />
			{children}
		</div>
	);
};

OptimizedImageBackground.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	width: PropTypes.number,
	style: PropTypes.shape({})
};

// Flattens all child elements into a single list
const flatten = (children, flat = []) => {
	flat = [...flat, ...React.Children.toArray(children)];

	if (children && children.props && children.props.children) {
		return flatten(children.props.children, flat);
	}

	return flat;
};

// Strips all circular references and internal fields
const simplify = (theChildren) => {
	const flat = flatten(theChildren);

	return flat.map(
		({ key, ref, type, props: { children: theChildren, ...props } }) => ({
			key,
			ref,
			type,
			props
		})
	);
};

function areEqual(prevProps, nextProps) {
	const { children: cPrev, ...prevPropsFiltered } = prevProps;
	const { children: cNext, ...nextPropsFiltered } = nextProps;
	return (
		JSON.stringify(prevPropsFiltered) ===
			JSON.stringify(nextPropsFiltered) &&
		JSON.stringify(simplify(cPrev)) === JSON.stringify(simplify(cNext))
	);
}

export default memo(OptimizedImageBackground, areEqual);
