import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'wsm-skeleton';
import AspectRatioBox from '../aspect-ratio-box/AspectRatioBox';
import { BLANK_GIF } from '../../global-constants';
import { isProgramAspectRatioAccount } from '../../utilities/aspect';

const CustomAssetSkeleton = ({ placeholderImage, windowId, account }) => {
	let skeletonContent;
	const programAspectRatioAccount =
		account && isProgramAspectRatioAccount(account);

	const isBmwMini =
		programAspectRatioAccount === 'bmw' ||
		programAspectRatioAccount === 'mini';

	const skeletonComponent = (
		<Skeleton
			placeholderImage={placeholderImage || BLANK_GIF}
			width="100%"
			height="100%"
			skeletonClasses={`custom-asset skeleton-shimmer custom-asset-placeholder-${windowId}`}
			imageAlt="blank gif"
		/>
	);

	if (isBmwMini) {
		skeletonContent = skeletonComponent;
	} else {
		skeletonContent = <AspectRatioBox isSkeleton>{skeletonComponent}</AspectRatioBox>;
	}

	return skeletonContent;
};

CustomAssetSkeleton.propTypes = {
	placeholderImage: PropTypes.string,
	windowId: PropTypes.string,
	account: PropTypes.shape({})
};

export default CustomAssetSkeleton;
