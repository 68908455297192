import React, { Fragment } from 'react';
import { templatePropTypes } from '../../utilities/commonPropTypes';
import { aR } from '../../global-constants';
import { Mobile6x1Fragment } from '../commonFragments';

const Mobile6x1 = ({
	promoIndex,
	promo,
	title,
	incentive = {},
	ctaButtonData,
	triggerPrint
}) => {
	return (
		<Fragment>
			{Mobile6x1Fragment({
				title,
				promoIndex,
				promo,
				incentive,
				aspectRatio: aR.MOBILE_6X1,
				ctaButtonData,
				triggerPrint
			})}
		</Fragment>
	);
};

Mobile6x1.propTypes = templatePropTypes;

export default Mobile6x1;
