import React from 'react';
import { useLabels, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { templatePropTypes } from '../../utilities/commonPropTypes';
import AspectRatioBox from '../../components/aspect-ratio-box/AspectRatioBox';
import OptimizedImageBackground from '../../components/OptimizedImageBackground';
import {
	ctaButtonFragment,
	detailsButtonFragment,
	offerDetailsFragment,
	ctaPrintFragment
} from '../commonFragments';
import {
	getVehicleImages,
	getIncentiveImage,
	getNoImageLabels,
	getFormattedDollarSign
} from '../../utilities/general.jsx';
import { g } from '../../global-constants';

const Desktop4x1 = ({
	applicableVehicle = '',
	promoIndex,
	couponCode = '',
	ctaButtonData,
	description,

	discountDisplay = '',
	imageUrl,
	incentive = {},
	isMobile,
	offerDetails,
	promo,
	title,
	type,
	vehicleImages = [],
	width,
	triggerPrint
}) => {
	let template;
	const labels = useLabels();
	const { reduceDollarSignSize, vehicleBackgroundImage } = usePrefs();
	const ctaButtonClasses = setClassNames([
		'btn-inverse',
		'd-table',
		'mb-3',
		'cta-btn'
	]);
	const ctaModalButtonClasses = setClassNames(['my-4']);
	const detailsButtonClasses = [
		'pl-0',
		'text-decoration-underline',
		'text-capitalize'
	];

	const noImageLabels = getNoImageLabels(labels, promo.inventoryCount);

	const sharedFragment = (
		<div className="text-wrapper d-flex">
			<div className="text d-flex flex-column justify-content-center">
				{offerDetailsFragment({
					couponCode,
					cta: ctaButtonFragment({
						promoIndex,
						ctaButtonClasses,
						ctaButtonData,
						promo
					}),
					description: getFormattedDollarSign(
						description,
						reduceDollarSignSize
					),
					details: detailsButtonFragment({
						promoIndex,
						detailsButtonClasses,
						promo,
						cta: ctaButtonFragment({
							promoIndex,
							ctaModalButtonClasses,
							ctaButtonData,
							promo
						}),
						ctaPrint: ctaPrintFragment({
							promo,
							pushOutsiderCtaPrint: triggerPrint,
						}),
					}),
					discountDisplay,
					labels,
					title: getFormattedDollarSign(title, reduceDollarSignSize)
				})}
			</div>
		</div>
	);

	if ([g.PARTS, g.SERVICE, g.EVENT].includes(type)) {
		template = (
			<div className="wrapper d-flex flex-1">
				{sharedFragment}
				<div className="stripe" />
				<OptimizedImageBackground
					className="image"
					style={{
						backgroundColor: 'transparent',
						backgroundImage: `url("${imageUrl}")`
					}}
					width={width / 2}
				/>
			</div>
		);
	}

	if (type && type === g.VEHICLE) {
		template = (
			<div className="wrapper d-flex flex-1">
				{sharedFragment}
				<div className="stripe" />
				<div className="vehicles d-flex justify-content-center align-middle">
					{getVehicleImages(vehicleImages, width / 2, isMobile, 0, {
						title: noImageLabels.title,
						subtitle: noImageLabels.subtitle,
						containerClasses: ['ml-sm-10 ml-md-15']
					})}
				</div>
				<OptimizedImageBackground
					className="image"
					style={{
						backgroundImage: `url("${vehicleBackgroundImage}")`
					}}
					width={width / 2}
				/>
			</div>
		);
	}

	if (type && type === g.INCENTIVE) {
		template = (
			<div className="wrapper d-flex flex-1">
				<div className="text-wrapper d-flex">
					<div className="text d-flex flex-column justify-content-center">
						<h2>
							<div className="pb-3">{applicableVehicle}</div>
							<div className="title font-weight-normal">
								{getFormattedDollarSign(
									title,
									reduceDollarSignSize
								)}
							</div>
						</h2>
						<p>
							{getFormattedDollarSign(
								offerDetails,
								reduceDollarSignSize
							)}
						</p>
						<div className="cta-wrapper">
							{ctaButtonFragment({
								promoIndex,
								ctaButtonClasses,
								ctaButtonData,
								promo
							})}
							{detailsButtonFragment({
								promoIndex,
								detailsButtonClasses,
								incentive,
								promo,
								cta: ctaButtonFragment({
									promoIndex,
									ctaModalButtonClasses,
									ctaButtonData,
									promo
								}),
								ctaPrint: ctaPrintFragment({
									promo,
									pushOutsiderCtaPrint: triggerPrint,							
								}),
							})}
						</div>
					</div>
				</div>
				<div className="stripe" />
				<div className="single-vehicle d-flex justify-content-center align-middle">
					{getIncentiveImage(
						imageUrl,
						width / 2,
						'', // leave empty unless we have a label other than YMMT
						isMobile,
						65,
						{
							title: noImageLabels.title,
							subtitle: noImageLabels.subtitle,
							containerClasses: ['ml-sm-10 ml-md-15']
						}
					)}
				</div>
				<OptimizedImageBackground
					className="image"
					style={{
						backgroundImage:
							'url("/static/assets/images/specials/fancy_garage.jpg")'
					}}
					width={width / 2}
				/>
			</div>
		);
	}

	return <AspectRatioBox>{template}</AspectRatioBox>;
};

Desktop4x1.propTypes = templatePropTypes;

export default Desktop4x1;
