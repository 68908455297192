import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { isBrowser } from 'ws-scripts/modules/environment';
import { trackEvent as ddcTrackEvent } from 'ddc-track-event';
import { getPlacement } from './aspect';
import { buildImpObject } from './utility';

export const trackEvent = (eventDetails) => {
	if (isBrowser) {
		const DDC = window.DDC || {};
		if (eventDetails.event === 'specialsEvent' && DDC.pushToGtmDataLayer) {
			DDC.pushToGtmDataLayer(eventDetails);
		} else {
			ddcTrackEvent(
				eventDetails.widgetName,
				eventDetails.windowId,
				eventDetails
			);
		}
	}
};

export const trackImpression = ({
	widgetName,
	windowId,
	aspectRatio,
	promo,
	index,
	personalized
}) => {
	if (promo) {
		trackEvent({
			widgetName,
			windowId,
			event: 'specialsEvent',
			specialType: 'impression',
			specialCategory:
				promo.type.charAt(0) + promo.type.slice(1).toLowerCase(),
			specialId: promo.id,
			specialPlacementType: getPlacement(aspectRatio),
			specialPersonalized: personalized,
			specialAutoGen: promo.autoGenerated,
			nonInteractive: true,
			promoIndex: index,
			customAsset: promo.customAssets
				? Object.getOwnPropertyNames(promo.customAssets).length > 0
				: false
		});
	}
};

export const trackMultiImpression = ({
	widgetName,
	windowId,
	aspectRatio,
	promos,
	personalized
}) => {
	if (promos && promos.length) {
		trackEvent({
			widgetName,
			windowId,
			event: 'specialsEvent',
			specialType: 'impression',
			specialCategory:
				promos[0].type.charAt(0) +
				promos[0].type.slice(1).toLowerCase(),
			specials: buildImpObject(promos),
			specialPlacementType: getPlacement(aspectRatio),
			specialPersonalized: personalized,
			nonInteractive: true
		});
	}
};

export const trackPerformanceToNR = (message, attribute = null) => {
	if (localStorage.getItem(message) === null) {
		const formattedAttr = attribute || Math.round(global.performance.now());
		localStorage.setItem(message, formattedAttr);
		setNewRelicCustomAttribute(message, formattedAttr);
	}
};
