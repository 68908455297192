/* eslint-disable react/no-unknown-property */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'wsm-skeleton';
import { setClassNames } from 'ddc-classnames-js';
import AspectRatioBox from '../aspect-ratio-box/AspectRatioBox';
import { BLANK_GIF, aR as ASPECT_RATIO } from '../../global-constants';

const DefaultImageSkeleton = ({
	ratio,
	displayLimit,
	enableCarousel,
	placeholderImage,
	aspectRatio,
	windowId,
	isServer,
	modelOfferBackgroundImage,
	type,
	title
}) => {
	const borderDivStyle = '1px solid #ddd';
	const borderStyle = `${
		enableCarousel === 'true' && displayLimit !== '1'
			? borderDivStyle
			: null
	}`;
	let styles;
	let PromoContentSkeleton = null;
	const isIncentive = !isServer && type === 'INCENTIVE';

	switch (aspectRatio) {
		case ASPECT_RATIO.MOBILE_6X1:
			PromoContentSkeleton = (
				<div
					style={{ aspectRatio: ratio }}
					className={setClassNames(['wrapper', 'd-flex', 'py-3'])}
				>
					<Skeleton width="15%" skeletonClasses="mr-2 ml-3 d-block" />
					<Skeleton width="55%" skeletonClasses="mx-2 title" />
					<Skeleton
						width="30%"
						skeletonClasses="ml-2 mr-3 cta-wrapper"
					/>
				</div>
			);
			styles = { border: borderStyle };
			break;
		case ASPECT_RATIO.DESKTOP_10X1:
			PromoContentSkeleton = (
				<div
					className={`d-flex flex-row w-100 default-asset-placeholder-incentive-${windowId}`}
					style={{
						aspectRatio: ratio,
						backgroundImage: isIncentive
							? `url(${modelOfferBackgroundImage})`
							: '',
						backgroundPosition: 'center bottom',
						backgroundSize: 'cover'
					}}
					fetchpriority="high"
				>
					<div className="w-25 d-flex justify-content-center align-items-center">
						<Skeleton
							placeholderImage={placeholderImage || BLANK_GIF}
							width={isIncentive ? '65%' : '100%'}
							height={!isIncentive && '100%'}
							skeletonClasses={`${
								!isIncentive && 'skeleton-shimmer'
							} default-asset-placeholder-${windowId} 
								default-asset-placeholder flex-shrink-0 fade-image-wrapper image`}
							imageAlt={title}
						/>
					</div>
					<div
						className="h-100 d-flex flex-column justify-content-center px-5"
						style={{ width: '60%' }}
					>
						<Skeleton height="24%" skeletonClasses="my-3" />
						<Skeleton height="18%" skeletonClasses="my-3" />
					</div>
					<div
						className="h-100 d-flex flex-column justify-content-center pr-5"
						style={{ width: '15%' }}
					>
						<Skeleton height="24%" skeletonClasses="my-3" />
						<Skeleton height="18%" skeletonClasses="my-3" />
					</div>
				</div>
			);
			styles = { borderBottom: borderStyle };
			break;
		case ASPECT_RATIO.MOBILE_4X3_SLIDESHOW:
			PromoContentSkeleton = (
				<div
					className={setClassNames([
						'd-flex',
						'promo-card',
						'flex-column',
						'h-100',
					])}
				>
					<div
						className={`
							w-100 d-flex justify-content-center align-items-center 
							default-asset-placeholder-incentive-${windowId} default-${type}`}
						style={{
							backgroundImage: isIncentive
								? `url(${modelOfferBackgroundImage})`
								: '',
							backgroundPosition: 'center bottom',
							backgroundSize: 'cover',
							height: '45%',
						}}
						fetchpriority="high"
					>
						<Skeleton
							placeholderImage={placeholderImage || BLANK_GIF}
							width={isIncentive ? '45%' : '100%'}
							height={!isIncentive && '100%'}
							skeletonClasses={`${
								!isIncentive && 'skeleton-shimmer'
							} default-asset-placeholder-${windowId} flex-grow-1 default-asset-placeholder`}
							imageAlt={title}
						/>
					</div>
					<Fragment>
						<div
							className="d-flex flex-column justify-content-center text"
							style={{ height: '60%', padding: '5px 20px' }}
						>
							<Skeleton height="30px" skeletonClasses="my-3" />
							<Skeleton height="25px" skeletonClasses="my-3" />
							<Skeleton
								height="15px"
								width="50%"
								skeletonClasses="my-3"
							/>
							<Skeleton height="15px" skeletonClasses="my-3" />
						</div>
					</Fragment>
				</div>
			);
			styles = { borderBottom: borderStyle };
			break;
		case ASPECT_RATIO.DESKTOP_4X1:
			PromoContentSkeleton = (
				<div
					className={`d-flex flex-row h-100 default-asset-placeholder-incentive-${windowId}`}
					fetchpriority="high"
					style={{
						backgroundImage: isIncentive
							? `url(${modelOfferBackgroundImage})`
							: '',
						backgroundPosition: 'center bottom',
						backgroundSize: 'cover'
					}}
				>
					<div className="w-50 d-flex justify-content-center align-items-center">
						<Skeleton
							width={isIncentive ? '65%' : '100%'}
							height={!isIncentive && '100%'}
							placeholderImage={placeholderImage || BLANK_GIF}
							skeletonClasses={`custom-asset ${
								!isIncentive && 'skeleton-shimmer'
							} default-asset-placeholder-${windowId} default-asset-placeholder`}
							imageAlt={title}
						/>
					</div>
					<div className="d-flex flex-column justify-content-center px-4 w-50">
						<Skeleton height="15%" skeletonClasses="my-3" />
						<Skeleton height="13%" skeletonClasses="my-3" />
						<Skeleton height="10%" skeletonClasses="my-3" />
						<Skeleton
							height="9%"
							width="50%"
							skeletonClasses="my-3"
						/>
						<Skeleton height="10%" skeletonClasses="my-3" />
					</div>
				</div>
			);
			break;
		default:
			break;
	}

	return (
		<AspectRatioBox innerWrapperStyle={styles} isSkeleton>
			{PromoContentSkeleton}
		</AspectRatioBox>
	);
};

DefaultImageSkeleton.propTypes = {
	ratio: PropTypes.string,
	enableCarousel: PropTypes.string,
	displayLimit: PropTypes.string,
	placeholderImage: PropTypes.string,
	aspectRatio: PropTypes.string,
	windowId: PropTypes.string,
	isServer: PropTypes.bool,
	modelOfferBackgroundImage: PropTypes.string,
	type: PropTypes.string,
	title: PropTypes.string
};

export default DefaultImageSkeleton;
