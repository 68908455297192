/* eslint-disable react-hooks/exhaustive-deps, no-unused-vars, react-hooks/rules-of-hooks, no-restricted-syntax, no-undef */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { useRequestData } from 'ws-scripts/modules/common';
import { isBrowser } from 'ws-scripts/modules/environment';
import { usePrefs, useFlags } from 'wsm-common-data';
import { trackPerformanceToNR } from '../utilities/tracking';
import TemplateWrapper from '../components/TemplateWrapper';
import { usePromos } from '../hooks/usePromos';
import { usePrePromos } from '../hooks/usePrePromos';
import {
	getCouponStatus,
	getProgramAspectRatio,
	isProgramAspectRatioAccount
} from '../utilities/aspect';
import { loadCssBundleByAspectRatio } from '../utilities/utility';
import { optimizeDefaultDownsize, optimizeImage } from '../utilities/images';
import { buildYmmtFilterObject } from '../utilities/promos';
import { shouldShowDetailsButton } from '../utilities/general';
import { useURLPrefs } from '../hooks/useURLPrefs';
import PromoSkeletonWrapper from '../components/Skeleton/PromoSkeletonWrapper';
import {
	aR as ASPECT_RATIO,
	aspectRatiosProportions
} from '../global-constants';

const WidgetContainer = () => {
	const widgetRef = useRef();
	// Create feature flag for skeleton (CLS)
	const flags = useFlags();
	const [containerWidth, setContainerWidth] = useState(0);
	const [promosReady, setPromosState] = useState(false);
	const [isSinglePromo, setSingleAssetStatus] = useState();
	const [hasSingleAssetDetails, setSingleAssetDetailsStatus] = useState();
	const [filterParams, setFilterParams] = useState({});
	const {
		aspectRatio,
		enableCarousel,
		displayLimit,
		modelOfferBackgroundImage,
		collection
	} = useURLPrefs();
	const { enableAppleWallet, enablePrint } = usePrefs();
	const { deviceType, windowId } = useRequestData();
	const isCoupon = getCouponStatus(aspectRatio);
	const isMobile = deviceType === 'MOBILE';
	const isPreFetching =
		isBrowser &&
		window?.DDC?.WidgetData['ws-specials']?.[windowId]?.preloadedPromos;
	let promosState;
	let prePromos;
	const [widthProportion, heightProportion] =
		aspectRatiosProportions.get(aspectRatio);
	// This not happens on coupon
	let ratioStyle =
		aspectRatio !== ASPECT_RATIO.MOBILE_4X3_COUPON &&
		aspectRatio !== ASPECT_RATIO.DESKTOP_4X3
			? `${widthProportion}/${heightProportion}`
			: undefined;

	// The param to detect the ws-specials render on top of page
	const { isPriorityContent } = useSelector((state) => state.positionData);
	const account = useSelector((state) => state.account);
	const programAspectRatioAccount = isProgramAspectRatioAccount(account);
	const isSlideshow =
		aspectRatio === ASPECT_RATIO.DESKTOP_4X1 ||
		aspectRatio === ASPECT_RATIO.MOBILE_4X3_SLIDESHOW;
	const programAspectRatio =
		programAspectRatioAccount && isSlideshow
			? getProgramAspectRatio(programAspectRatioAccount)[
					deviceType.toLowerCase()
			  ]
			: undefined;
	if (programAspectRatio) {
		ratioStyle = `${programAspectRatio.width}/${programAspectRatio.height}`;
	}

	if (isPreFetching) {
		[prePromos] = usePrePromos();
	} else {
		[promosState] = usePromos();
	}

	const { promos, isLoading } = isPreFetching
		? prePromos
		: promosState;

	let aspectRatioClass;
	const serverRender =
		(!isBrowser || isLoading || !promos) &&
		(aspectRatio !== ASPECT_RATIO.DESKTOP_4X3 ||
			aspectRatio !== ASPECT_RATIO.MOBILE_4X3_COUPON);

	useEffect(() => {
		trackPerformanceToNR('ws-specials-starting');
		const perfEntries = global.performance.getEntriesByType('navigation');
		const PerformanceNavTiming = perfEntries[0];
		trackPerformanceToNR(
			'ws-specials-request-start',
			PerformanceNavTiming.requestStart
		);
		trackPerformanceToNR(
			'ws-specials-ttfb',
			PerformanceNavTiming.responseStart
		);
	}, []);

	useEffect(() => {
		loadCssBundleByAspectRatio(aspectRatio, collection);
	}, [aspectRatio]);

	useEffect(() => {
		window?.DDC?.pubsub?.subscribe('ws-inv-data/facets', () => {
			if (
				[ASPECT_RATIO.DESKTOP_10X1, ASPECT_RATIO.MOBILE_6X1].includes(
					aspectRatio
				)
			) {
				const filteredValues = window.DDC.InvData.getAppliedFilters();
				const flatFilteredValue = buildYmmtFilterObject(filteredValues);
				setFilterParams(flatFilteredValue);
			}
		});
	}, []);

	const filteredPromos = useMemo(() => {
		let validPromos = promos;
		if (promos && promos.length) {

			validPromos = promos.filter(
				(promo) =>
					!promo.incentiveId ||
					promo?.incentiveData
			);

			const singlePromo =
				validPromos.length === 1 ? validPromos[0] : undefined;
			setSingleAssetStatus(singlePromo);
			if (
				singlePromo &&
				typeof singlePromo === 'object' &&
				Object.keys(singlePromo).length > 0
			) {
				const singleAssetHasDetails = shouldShowDetailsButton(
					singlePromo,
					singlePromo.type === 'incentive' ? singlePromo : undefined,
					aspectRatio,
					enableAppleWallet,
					enablePrint
				);
				setSingleAssetDetailsStatus(singleAssetHasDetails);
			}
		}
		return validPromos;
	}, [promos]);

	// Set new widget containerWidth when parent element is resized, to preserve the aspect ratio
	useEffect(() => {
		if (isLoading) {
			return () => {};
		}
		const widgetWrapper = widgetRef.current.parentElement;
		let observer;
		let mutObserver;

		// ResizeObserver is not polyfilled, so check for it first
		if (typeof ResizeObserver === 'function') {
			// Watch for parent element dimension change
			observer = new ResizeObserver((entry) => {
				setContainerWidth(entry[0].contentRect.width);
			});
			observer.observe(widgetWrapper);
		} else {
			// Set initial width here for browsers that do not support ResizeObserver
			setContainerWidth(widgetWrapper.getBoundingClientRect().width);
		}

		if (
			flags['enabled-skeleton-ws-specials'] &&
			typeof MutationObserver === 'function'
		) {
			const config = {
				attributes: false,
				childList: true,
				subtree: true
			};

			mutObserver = new MutationObserver((wrapper, conf) => {
				for (const mutation of wrapper) {
					if (mutation.removedNodes.length > 0) {
						for (let i = 0; i < mutation.removedNodes.length; i++) {
							const removedNode = mutation.removedNodes[i];
							if (
								removedNode &&
								'id' in removedNode &&
								removedNode.id === 'top-skeleton-wrapper'
							) {
								setPromosState(true);
								break;
							}
						}
					}
				}
			});
			mutObserver.observe(widgetWrapper, config);
		}

		// Cleanup
		return () => {
			if (observer) observer.unobserve(widgetWrapper);
			if (mutObserver && typeof mutObserver.unobserve === 'function')
				mutObserver.unobserve(widgetWrapper);
		};
	}, [widgetRef, isLoading]);

	switch (aspectRatio) {
		case 'Mobile4x3_slideshow':
			aspectRatioClass = `aspect-ratio-mobile-4x3 coupon-listing slideshow ${
				!isSinglePromo && 'coupon-carousel'
			}`;
			break;
		case 'Mobile4x3_coupon':
			aspectRatioClass = `aspect-ratio-mobile-4x3 coupon-listing ${
				enableCarousel === 'true' && 'coupon-carousel'
			}`;
			break;
		case 'Mobile6x1':
			aspectRatioClass = `aspect-ratio-mobile-6x1 ${
				hasSingleAssetDetails ? 'mb-4' : ''
			}`;
			break;
		case 'Desktop4x1':
			aspectRatioClass = 'aspect-ratio-desktop-4x1 overflow-hidden';
			break;
		case 'Desktop4x3':
			aspectRatioClass = `aspect-ratio-desktop-4x3 coupon-listing ${
				enableCarousel === 'true' && filteredPromos.length > 2
					? 'coupon-carousel mb-8'
					: ''
			}`;
			break;
		case 'Desktop10x1':
			aspectRatioClass = `aspect-ratio-desktop-10x1 ${
				!isSinglePromo || hasSingleAssetDetails ? 'mb-8' : ''
			}`;
			break;
		default:
			aspectRatioClass = 'aspect-ratio-desktop-4x1 overflow-hidden';
			break;
	}

	const containerClasses = [
		aspectRatioClass,
		!isLoading &&
		(!promos || promos.length === 0)
			? 'd-none'
			: '',
		'position-relative'
	];

	const aspectRatioContainerClasses = ['w-100', 'bg-transparent'];

	const props = {
		aspectRatio,
		isMobile,
		width: containerWidth,
		isCoupon
	};

	const wsSpecialComponent = () => {
		if (serverRender && isPriorityContent) {
			return (
				<PromoSkeletonWrapper
					isServer
					ratio={ratioStyle}
					aspectRatio={aspectRatio}
					enableCarousel={enableCarousel}
					displayLimit={displayLimit}
					windowId={windowId}
					account={account}
				/>
			);
		} else if (filteredPromos?.length) {
			// Get 1st special to improve LCP
			const {
				customAssets = {},
				imageUrl = '',
				type,
				title
			} = filteredPromos.length && filteredPromos[0];
			// This is not happen for coupon
			const hasCoupon =
				aspectRatio === ASPECT_RATIO.DESKTOP_4X3 ||
				aspectRatio === ASPECT_RATIO.MOBILE_4X3_COUPON;

			const csrPlaceholder =
				isPriorityContent && !hasCoupon ? (
					<PromoSkeletonWrapper
						title={title}
						modelOfferBackgroundImage={
							optimizeDefaultDownsize(
								modelOfferBackgroundImage,
								deviceType,
								aspectRatio
							).src
						}
						type={type}
						ratio={ratioStyle}
						filteredPromosLength={filteredPromos.length}
						windowId={windowId}
						aspectRatio={aspectRatio}
						enableCarousel={enableCarousel}
						displayLimit={displayLimit}
						customAssets={
							optimizeImage(
								customAssets[aspectRatio],
								containerWidth
							).src
						}
						imageUrl={optimizeImage(imageUrl, containerWidth).src}
						account={account}
					/>
				) : null;
			return (
				<TemplateWrapper
					promos={filteredPromos}
					{...props}
					filterParams={filterParams}
					csrPlaceholder={csrPlaceholder}
				/>
			);
		} else {
			return null;
		}
	};

	return (
		<div ref={widgetRef} className={setClassNames(containerClasses)}>
			{!flags['enabled-skeleton-ws-specials'] ? (
				<div className={setClassNames(aspectRatioContainerClasses)}>
					{filteredPromos && filteredPromos.length ? (
						<TemplateWrapper
							promos={filteredPromos}
							filterParams={filterParams}
							{...props}
						/>
					) : null}
				</div>
			) : (
				<div
					className={setClassNames(aspectRatioContainerClasses)}
					id={`ws-specials-inner-${windowId}`}
					style={
						!promosReady
							? { aspectRatio: isPriorityContent && ratioStyle }
							: {}
					}
				>
					{wsSpecialComponent()}
				</div>
			)}
		</div>
	);
};

WidgetContainer.propTypes = {};

export default WidgetContainer;
