/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import { useRequestData } from 'ws-scripts/modules/common';
import PropTypes from 'prop-types';
import { useURLPrefs } from '../../hooks/useURLPrefs';
import {
	isProgramAspectRatioAccount,
	getProgramAspectRatio
} from '../../utilities/aspect';
import {
	aR as ASPECT_RATIO,
	aspectRatiosProportions
} from '../../global-constants';

import './AspectRatioBox.scss';

const AspectRatioBox = ({ children, innerWrapperStyle, isSkeleton }) => {
	const { aspectRatio } = useURLPrefs();
	const { deviceType } = useRequestData();
	const [widthProportion, heightProportion] =
		aspectRatiosProportions.get(aspectRatio);
	const containerStyle = {};
	// Only applies to slideshow
	let ratioStyle =
		aspectRatio !== ASPECT_RATIO.MOBILE_4X3_COUPON &&
		aspectRatio !== ASPECT_RATIO.DESKTOP_4X3
			? `${widthProportion}/${heightProportion}`
			: undefined;
	const account = useSelector((state) => state.account);
	const isSlideshow =
		aspectRatio === ASPECT_RATIO.DESKTOP_4X1 ||
		aspectRatio === ASPECT_RATIO.MOBILE_4X3_SLIDESHOW;
	const programAspectRatioAccount = isProgramAspectRatioAccount(account);
	const programAspectRatio =
		programAspectRatioAccount && isSlideshow
			? getProgramAspectRatio(programAspectRatioAccount)[
					deviceType.toLowerCase()
			  ]
			: undefined;
	const isFixedProgramAspectRatio =
		programAspectRatioAccount === 'mazda' ||
		programAspectRatioAccount === 'stellantis';
	const fixedProgramAspectRatio =
		programAspectRatio && isFixedProgramAspectRatio;
	if (fixedProgramAspectRatio) {
		ratioStyle = `${programAspectRatio.width}/${programAspectRatio.height}`;
		containerStyle.aspectRatio = ratioStyle;
		containerStyle.height = 'auto';
	}

	return (
		<div
			className={`aspect-ratio-${widthProportion}-${heightProportion}`}
			style={{ ...containerStyle, aspectRatio: ratioStyle }}
		>
			<div
				className={`aspect-ratio-box-inner-wrapper ${
					fixedProgramAspectRatio && !isSkeleton
						? 'fixed-program-aspect-ratio'
						: ''
				} `}
				style={{ ...innerWrapperStyle, border: '0' }}
			>
				{children}
			</div>
		</div>
	);
};

AspectRatioBox.propTypes = {
	innerWrapperStyle: PropTypes.shape({}),
	isSkeleton: PropTypes.bool
};

export default AspectRatioBox;
