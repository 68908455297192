/* eslint-disable camelcase */
import * as Fade from './fade';
import * as Slant from './slant';
import * as Block from './block';

const templates = {
	Fade,
	Block,
	Slant
};

export default templates;
