import { g } from '../global-constants';
import { trackEvent } from './tracking';
import { getPlacement } from './aspect';

export const getDestinationLink = ({
	condition,
	label,
	url,
	inventoryCount,
	isCustomAsset,
	labels,
	sitemap,
	type
}) => {
	let retUrl;
	let retLabel;
	let defaultLabel;
	let defaultUrl;

	switch (type) {
		case g.EVENT:
			retLabel = label === 'default' ? '' : labels.get(label);
			retUrl = url === 'default' ? '' : url;
			break;
		case g.PARTS:
			retLabel = label === 'default' ? '' : labels.get(label);
			retUrl = url === 'default' ? '' : url;
			break;
		case g.SERVICE:
			retLabel = label === 'default' ? '' : labels.get(label);
			retUrl = url === 'default' ? '' : url;
			break;
		case g.VEHICLE:
		case g.INCENTIVE:
			// Vehicle promos should always have a url, created by the backend
			// defaultUrl should only be needed for incentive promos
			defaultLabel = labels.get('VIEW_[NUMBER]_QUALIFYING_VEHICLE(S)');
			defaultLabel = defaultLabel.replace(
				'[number]',
				`${inventoryCount}`
			);

			defaultUrl =
				condition === 'CPO'
					? sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_USED')
					: sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_NEW');

			retLabel =
				!label ||
				label === 'default' ||
				label === 'VIEW_[NUMBER]_QUALIFYING_VEHICLE(S)'
					? defaultLabel
					: labels.get(label);
			retUrl = !url || url === 'default' ? defaultUrl : url;
			break;
		default:
		// no-op
	}

	return {
		url: retUrl,
		label: retLabel,
		isFullSlideLink: isCustomAsset && retUrl
	};
};

export const getClickHandler = (type, data) => {
	if (type === 'Asset') {
		const {
			widgetName,
			windowId,
			promo,
			aspectRatio,
			personalized,
			promoIndex
		} = data;

		const {
			autoGenerated,
			id,
			incentiveData: { title: incentiveTitle } = {},
			oemId,
			title,
			type: promoType
		} = promo;

		return ($event) => {
			const { target } = $event;
			if ($event.target && target.classList.contains('custom-asset')) {
				const event = {
					widgetName,
					windowId,
					event: 'specialsEvent',
					specialType: 'click',
					specialCategory:
						promoType.charAt(0) + promoType.slice(1).toLowerCase(),
					specialId: id,
					specialPlacementType: getPlacement(aspectRatio),
					specialPersonalized: personalized,
					specialAutoGen: autoGenerated,
					nonInteractive: false,
					promoIndex,
					customAsset: true,
					oemId,
					title: title || incentiveTitle
				};
				trackEvent(event);
			}
		};
	} else {
		return () => {};
	}
};
