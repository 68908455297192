import { a } from '../global-constants';

const hasdata = (state = false, { type, payload }) => {
	switch (type) {
		case a.SET_HAS_DATA: {
			return payload;
		}
		default:
			return state;
	}
};

export default hasdata;
