import { a } from '../global-constants';

const modal = (state = {}, { type }) => {
	switch (type) {
		case a.OPEN_MODAL: {

			return {
				...state,
				isModalOpen: true
			};
		}
		case a.CLOSE_MODAL: {
			return {
				...state, 
				isModalOpen: false
			};
		}
		default:
			return state;
	}
};

export default modal;
