/* eslint-disable no-restricted-syntax */
import { g } from '../global-constants';
import { optimizeImage } from './images';

export const buildYmmtFilterObject = (filterValues) => {
	const ymmtAttrs = ['year', 'make', 'model', 'trim'];
	let flatFilteredValue = {};
	filterValues.forEach((fv) => {
		if (ymmtAttrs.includes(fv.id)) {
			flatFilteredValue = {
				...flatFilteredValue,
				[`${fv.id}`]: fv.values.map((fv2) => fv2.value)
			};
		}
	});
	return flatFilteredValue;
};

/**
 * Specificly check on Year facet.
 * 1. If there is no Year facet or there is no Year rule in vehicle promotion,
 * it means this promotion will be returned
 * 2. Otherwise, it has to be match Year rule to be returned
 */
const isYearMatch = (yearValue, yearParams) => {
	if (!yearValue || yearParams.length === 0) {
		return true;
	}

	const { min, max } = yearValue;

	return yearParams.some(
		(year) => Number(year) >= min && Number(year) <= max
	);
};

/**
 * We implement a fall-back return based on the first condition that is met.
 * 1. Matched exact Make - Model - Trim
 * or 2. Matched exact Make - Model
 * or 3. Matched exact Make
 * or 4. Match any Make - Model - Trim
 * @param  {...any} matches
 * @returns
 */
const getExactMatches = (...matches) => {
	return matches.find((match) => match && match.size > 0) || null;
};

/**
 * Convert rule from Array format to Object format
 * In: [ {field: 'MAKE', value: 'Subaru' }
 * Out: { MAKE: 'Subaru' }
 * @param {*} ruleItems
 * @returns
 */
const transformRuleItem = (ruleItems) => {
	const transformedRuleItem = {
		MAKE: '',
		MODEL: '',
		TRIM: '',
		YEAR: ''
	};

	for (const ruleItem of ruleItems) {
		transformedRuleItem[ruleItem.field] = ruleItem.value;
	}
	return transformedRuleItem;
};

const updateMapping = (mapping, key, value) => {
	if (mapping[key]) {
		mapping[key].add(value);
	} else {
		// eslint-disable-next-line no-param-reassign
		mapping[key] = new Set([value]);
	}
};

/**
 * This is to build valid Make-Model-Trim mapping which is inferred from data
 * [key] will be [make]:[model]:[trim]
 * [value] will be promo ID which match the [key]
 * Special key [::] means promotion targeting to YEAR only, so it matches All
 */
const buildMakeModelTrimMapping = (promos, makes, models, trims, years) => {
	// These variables are used to guess Make, Model in case it is missing
	const guessedMakes = new Set([]);
	const guessedModels = new Set([]);

	const mmtMapping = {};

	for (const promo of promos) {
		for (const ruleItem of promo.filterRules) {
			const transformedRuleItem = transformRuleItem(ruleItem);

			// This promo only has YEAR facet, so it will make ALL
			if (transformedRuleItem.YEAR && !transformedRuleItem.MAKE) {
				updateMapping(mmtMapping, '::', promo.id);
			}

			if (isYearMatch(transformedRuleItem.YEAR, years)) {
				if (trims.includes(transformedRuleItem.TRIM)) {
					if (!models.length) {
						guessedModels.add(transformedRuleItem.MODEL);
						updateMapping(
							mmtMapping,
							`${transformedRuleItem.MAKE}:${transformedRuleItem.MODEL}`,
							promo.id
						);
					}
					if (!makes.length) {
						guessedMakes.add(transformedRuleItem.MAKE);
						updateMapping(
							mmtMapping,
							transformedRuleItem.MAKE,
							promo.id
						);
					}
					const key = `${transformedRuleItem.MAKE}:${transformedRuleItem.MODEL}:${transformedRuleItem.TRIM}`;
					updateMapping(mmtMapping, key, promo.id);
				}

				if (models.includes(transformedRuleItem.MODEL)) {
					if (!makes.length) {
						guessedMakes.add(transformedRuleItem.MAKE);
						updateMapping(
							mmtMapping,
							transformedRuleItem.MAKE,
							promo.id
						);
					}
					const key = `${transformedRuleItem.MAKE}:${transformedRuleItem.MODEL}`;
					updateMapping(mmtMapping, key, promo.id);
				}

				if (makes.includes(transformedRuleItem.MAKE)) {
					const key = `${transformedRuleItem.MAKE}`;
					updateMapping(mmtMapping, key, promo.id);
				}
			}
		}
	}

	return {
		mmtMapping,
		makes: makes.length ? makes : Array.from(guessedMakes),
		models: models.length ? models : Array.from(guessedModels),
		trims
	};
};

export const filterVehiclePromosByYmmt = (promos, filterValues) => {
	const filterKeys = Object.keys(filterValues);
	if (!filterKeys.length) return promos;

	const vehiclePromos = promos.filter((promo) => promo.type === g.VEHICLE);
	if (!vehiclePromos.length) return promos;

	const { mmtMapping, makes, models, trims } = buildMakeModelTrimMapping(
		vehiclePromos,
		filterValues.make || [],
		filterValues.model || [],
		filterValues.trim || [],
		filterValues.year || []
	);

	let trimMatches = new Set([]);
	let modelMatches = new Set([]);
	let makeMatches = new Set([]);

	for (const make of makes) {
		const makeKey = `${make}`;
		if (mmtMapping[makeKey]) {
			makeMatches = new Set([...makeMatches, ...mmtMapping[makeKey]]);

			for (const model of models) {
				// not valid make-model or no data for make-model
				const modelKey = `${make}:${model}`;
				if (mmtMapping[modelKey]) {
					modelMatches = new Set([
						...modelMatches,
						...mmtMapping[modelKey]
					]);

					for (const trim of trims) {
						// not valid make-model-trim or no data for make-model-trim
						const trimKey = `${make}:${model}:${trim}`;
						if (mmtMapping[trimKey]) {
							trimMatches = new Set([
								...trimMatches,
								...mmtMapping[trimKey]
							]);
						}
					}
				}
			}
		}
	}

	const matchedPromoIds = getExactMatches(
		trimMatches,
		modelMatches,
		makeMatches,
		mmtMapping['::']
	);

	if (!matchedPromoIds) {
		return promos;
	} else {
		return vehiclePromos.filter((promo) => matchedPromoIds.has(promo.id));
	}
};

/**
 * Reduce all image size from promos
 */
export const reduceImageSizeFromPromo = (promos, width, aspectRatio) => {
	return promos.map((p) => {
		const optimizedImg = optimizeImage(p.imageUrl, width);
		const optimizedCustomAssetImg = optimizeImage(
			p.customAssets[aspectRatio],
			width
		);
		return {
			...p,
			imageUrl: optimizedImg.src,
			customAssets: {
				[`${aspectRatio}`]: optimizedCustomAssetImg.src
			}
		};
	});
};
