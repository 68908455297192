import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useLabels, useRequestData } from 'wsm-common-data';
import { useURLPrefs } from '../hooks/useURLPrefs';
import { g } from '../global-constants';
import { getDiscountDisplay, getPhoneNumber } from '../utilities/general';
import { formatPerpetualDates } from '../utilities/utility';
import { parseText, formatIncentiveDate } from '../utilities/incentive';
import { promoPropTypes } from '../utilities/commonPropTypes';
import '../global-sass/PrintView.scss';

const PrintView = forwardRef((props, ref) => {
	const {
		title,
		description,
		additionalDetails,
		disclaimer,
		endDateDisplay = '',
		startDateDisplay = '',
		couponCode,
		type,
		discountType,
		discountValue,
		incentiveData
	} = props.promo;
	const { componentToPrintRef, isDisclosure } = props;
	const getAspectRatio = useURLPrefs();
	const labels = useLabels();
	const { locale } = useRequestData();
	const {
		phoneNumbers = [],
		dealerInfo: {
			name: dealershipName = '',
			state: dist = '',
			postalCode = '',
			address1 = '',
			address2 = '',
			address3 = '',
			city = ''
		} = {}
	} = useSelector((state) => state.account);

	let discount = null;
	let incentive;
	let effectiveDates = '';

	if ([g.PARTS, g.SERVICE].includes(type) && discountType !== 'NO_DISCOUNT') {
		discount = getDiscountDisplay(
			locale,
			discountType,
			discountValue,
			labels
		);
	}

	if (type === 'INCENTIVE' && incentiveData) {
		incentive = formatIncentiveDate(incentiveData, locale);

		effectiveDates = `${labels.get('OFFER_ONLY_VALIDATE')} ${
			incentive?.effectiveDate
		} ${labels.get('THROUGH')} ${incentive?.expirationDate}`;
	}

	const offerDetailsField = type === 'INCENTIVE' && incentive?.offerDetails;
	const disclaimerField =
		type !== 'INCENTIVE' ? disclaimer : incentive?.disclaimer;
	const trimsField = type === 'INCENTIVE' && incentive?.applicableTrims;

	const reactToPrintContent = () => componentToPrintRef.current;

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: `${getAspectRatio.aspectRatio}`,
		removeAfterPrint: false
	});

	// a Hook to calling child component function from Parent component
	useImperativeHandle(ref, () => ({
		onPrintView: handlePrint
	}));

	return (
		<div className="print-view-details" style={{ fontFamily: 'Arial' }}>
			{dealershipName && (
				<div className="dealership">{dealershipName}</div>
			)}
			{address1 && (
				<div className="address1">
					{address1}. {city}, {dist} {postalCode}
				</div>
			)}
			{address2 && (
				<div className="address2">
					{address2}. {city}, {dist} {postalCode}
				</div>
			)}
			{address3 && (
				<div className="address3">
					{address3}. {city}, {dist} {postalCode}
				</div>
			)}
			{phoneNumbers.length ? (
				<div
					className="phone"
					dangerouslySetInnerHTML={{
						__html: getPhoneNumber(phoneNumbers, labels)
					}}
				/>
			) : (
				''
			)}
			<div className="underline" />
			<div className="heading">
				{type !== 'INCENTIVE' ? title : incentive?.title}
			</div>
			<div className="start-end-date">
				<div
					dangerouslySetInnerHTML={{
						__html:
							type !== 'INCENTIVE'
								? formatPerpetualDates(
										startDateDisplay,
										endDateDisplay,
										labels
								  )
								: effectiveDates
					}}
				/>
			</div>
			{offerDetailsField && (
				<div className="offer-details">{offerDetailsField}</div>
			)}
			{description && <div className="description">{description}</div>}
			{discount && <div className="discount">{discount}</div>}
			{additionalDetails && (
				<div className="additional-details">
					<div className="h5 details">{labels.get('DETAILS')}:</div>
					<div>{parseText(additionalDetails)}</div>
				</div>
			)}
			{couponCode && (
				<div className="coupon-code">
					<div className="h5 coupon">
						{labels.get('COUPON_CODE')}:
					</div>
					{couponCode}
				</div>
			)}
			{trimsField && trimsField.length > 0 && (
				<>
					<div className="applicable-trims">
						{labels.get('APPLICABLE_TRIMS')}:
					</div>
					<div>{trimsField}</div>
				</>
			)}
			{disclaimerField && (
				<>
					<div className="disclaimer-title">
						{isDisclosure ? labels.get('DISCLOSURE') : labels.get('DISCLAIMERS')}:
					</div>
					<div className="text-muted small">
						{parseText(disclaimerField)}
					</div>
				</>
			)}
		</div>
	);
});

PrintView.propTypes = {
	promo: PropTypes.shape(promoPropTypes),
	componentToPrintRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	isDisclosure: PropTypes.bool
};
export default PrintView;
