import { hysteriFetch, wrappers } from 'hysterics';

class ProxyServiceProvider {
	getInstance(timeout, retries) {
		if (!this.proxyService) {
			this.proxyService = hysteriFetch('specialsData', {
				[wrappers.timeout.contextKey]: {
					timeout
				},
				[wrappers.retries]: retries
			});
		}
		return this.proxyService;
	}
}

const proxyServiceProvider = new ProxyServiceProvider();

export { proxyServiceProvider };
