import { aR, aspectRatios, programsApectRatios, groupsWithSpecialAspectRatio as groups } from '../global-constants';

export const getPlacement = (aspectRatio) => {
	return aspectRatios.get(aspectRatio);
};

export const getCouponStatus = (aspectRatio) => {
	return (
		aspectRatio === aR.DESKTOP_4X3 ||
		aspectRatio === aR.MOBILE_4X3_COUPON ||
		aspectRatio === aR.MOBILE_4X3_SLIDESHOW
	);
};

export const isMobile4x3 = (aspectRatio) => {
	return (
		aspectRatio === aR.MOBILE_4X3_SLIDESHOW ||
		aspectRatio === aR.MOBILE_4X3_COUPON
	);
};

export const getCouponCaroDisplay = (width) => {
	return width + 48 > 992 ? 3 : 2;
};

export const getProgramAspectRatio = (key) => {
	return programsApectRatios[key];
};

export const isProgramAspectRatioAccount = (accountData) => {
	const { id, topLevelAccount } = accountData;
	let specialAspectRatio;
	groups.every((group) => {
		const { name, members } = group;
		if (
			members.includes(id) ||
			(topLevelAccount &&
				topLevelAccount.some((topAcc) => members.includes(topAcc)))
		) {
			specialAspectRatio = name;
			return false;
		}
		return true;
	});
	return specialAspectRatio;
};
