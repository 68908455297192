import PropTypes from 'prop-types';

export const templatePropTypes = {
	ctaButtonData: PropTypes.shape({
		ctaUrl: PropTypes.string,
		ctaLabel: PropTypes.string
	}).isRequired,
	description: PropTypes.string,
	detailsButtonData: PropTypes.shape({
		id: PropTypes.string,
		type: PropTypes.string
	}),
	imageUrl: PropTypes.string,
	title: PropTypes.elementType.isRequired,
	vehicleBackgroundImage: PropTypes.string,
	modelOfferBackgroundImage: PropTypes.string
};

export const promoPropTypes = {
	id: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	accountId: PropTypes.string.isRequired,
	additionalDetails: PropTypes.string,
	autoGenerated: PropTypes.bool.isRequired,
	couponCode: PropTypes.string,
	ctaConfig: PropTypes.shape({
		type: PropTypes.string.isRequired,
		openInNewTab: PropTypes.bool.isRequired,
		dynamicParams: PropTypes.shape({}).isRequired
	}),
	customAssets: PropTypes.shape({}).isRequired,
	description: PropTypes.string,
	destinationLabel: PropTypes.string,
	disclaimer: PropTypes.string,
	discountType: PropTypes.string,
	discountValue: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.node
	]),
	endDate: PropTypes.string.isRequired,
	endDateDisplay: PropTypes.string.isRequired,
	imageUrl: PropTypes.string,
	locked: PropTypes.bool.isRequired,
	mandatory: PropTypes.bool.isRequired,
	oemId: PropTypes.string,
	placement: PropTypes.arrayOf(
		PropTypes.shape({
			enabled: PropTypes.bool.isRequired,
			i18n: PropTypes.shape({}).isRequired,
			location: PropTypes.string.isRequired
		})
	).isRequired,
	startDate: PropTypes.string.isRequired,
	startDateDisplay: PropTypes.string.isRequired,
	tags: PropTypes.array,
	title: PropTypes.string.isRequired,
	userDefinedPriority: PropTypes.number.isRequired
};

export const promoComponentPropTypes = {
	additionalDetails: PropTypes.string,
	autoGenerated: PropTypes.bool,
	condition: PropTypes.string,
	couponCode: PropTypes.string,
	customAssets: PropTypes.shape(),
	destinationLabel: PropTypes.string,
	destinationUrl: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	disclaimer: PropTypes.string,
	discountType: PropTypes.string,
	discountValue: PropTypes.number,
	globalVehicleId: PropTypes.string,
	id: PropTypes.string.isRequired,
	imageUrl: PropTypes.string,
	incentiveId: PropTypes.string,
	inventoryCount: PropTypes.number,
	type: PropTypes.string.isRequired,
	vehicleImages: PropTypes.arrayOf(PropTypes.string),
	oemId: PropTypes.string,
	ctaConfig: PropTypes.shape({
		openInNewTab: PropTypes.bool
	}),
	incentiveData: PropTypes.shape({
		id: PropTypes.string,
		applicableCondition: PropTypes.string,
		applicableMake: PropTypes.string,
		incentive: PropTypes.shape({
			type: PropTypes.string
		}),
		incentiveEffectiveDate: PropTypes.string,
		incentiveExpirationDate: PropTypes.string,
		globalVehicleId: PropTypes.string,
		globalVehicleTrimIds: PropTypes.arrayOf(PropTypes.string)
	})
};

export const ctaComponentProps = {
	promoIndex: PropTypes.number,
	customAsset: PropTypes.bool,
	ctaButtonClasses: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.string
	]),
	label: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	promo: PropTypes.shape(promoPropTypes).isRequired
};

export const appleWalletComponentProps = {
	tabIndexBase: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	discountValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node
	]),
	discountType: PropTypes.string,
	disclaimer: PropTypes.string,
	incentive: PropTypes.shape({}),
	applicableVehicle: PropTypes.string,
	description: PropTypes.string,
	endDateDisplay: PropTypes.string,
	id: PropTypes.string.isRequired,
	promoDetails: PropTypes.string,
	promoOemId: PropTypes.string
};
