import React, { Fragment } from 'react';
import { templatePropTypes } from '../../utilities/commonPropTypes';
import { Shared4x3Fragment } from '../commonFragments';

const Mobile4x3 = ({
	applicableVehicle,
	promoIndex,
	couponCode = '',
	ctaButtonData,
	description,
	discountDisplay = '',
	imageUrl,
	incentive,
	offerDetails,
	promo,
	title,
	type,
	vehicleImages = [],
	width,
	height,
	vehicleBackgroundImage,
	modelOfferBackgroundImage
}) => {
	return (
		<Fragment>
			{Shared4x3Fragment({
				applicableVehicle,
				promoIndex,
				couponCode,
				ctaButtonData,
				description,
				discountDisplay,
				imageUrl,
				offerDetails,
				promo,
				incentive,
				title,
				type,
				vehicleImages,
				width,
				height,
				vehicleBackgroundImage,
				modelOfferBackgroundImage
			})}
		</Fragment>
	);
};

Mobile4x3.propTypes = templatePropTypes;

export default Mobile4x3;
