import React from 'react';
import PropTypes from 'prop-types';
import { useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';

import { aR } from '../global-constants';

const NoImage = ({
	title,
	subtitle,
	containerClasses = [],
	containerStyles = {},
	aspectRatio
}) => {
	const { deviceType } = useRequestData();
	const isMobile = deviceType === 'MOBILE';
	const noImageContainerClasses = [
		'd-flex',
		'flex-column',
		'justify-content-center',
		'align-items-center',
		'font-weight-bold',
		'overflow-hidden',
		'bg-white',
		isMobile ? 'no-image-container-mobile' : 'no-image-container',
		...containerClasses
	];
	return (
		<div
			className={setClassNames(noImageContainerClasses)}
			style={{
				...containerStyles
			}}
		>
			<img src="/static/assets/images/specials/no-image.svg" alt="" />
			{aspectRatio === aR.DESKTOP_10X1 ? (
				<h3 className="text-body m-0 pt-3 text-center">
					{title}
					{subtitle ? (
						<div className="h3 text-muted">{subtitle}</div>
					) : null}
				</h3>
			) : (
				<h2 className="text-black pb-3 text-center">
					{title}
					{subtitle ? (
						<div className="h3 text-muted">{subtitle}</div>
					) : null}
				</h2>
			)}
		</div>
	);
};

NoImage.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	aspectRatio: PropTypes.string,
	containerClasses: PropTypes.arrayOf(PropTypes.string),
	containerStyles: PropTypes.shape({})
};

export default NoImage;
