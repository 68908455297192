import { useSelector } from 'react-redux';
import { usePrefs } from 'wsm-common-data';

const useURLPrefs = () => {
	const {
		enableCarouselTest,
		aspectRatioTest,
		specialCountTest,
		collectionTest
	} = useSelector((state) => state.testParams);
	const { enableCarousel, aspectRatio, collection, displayLimit, modelOfferBackgroundImage } =
		usePrefs();

	return {
		enableCarousel: enableCarouselTest || enableCarousel,
		aspectRatio: aspectRatioTest || aspectRatio,
		specialCount: specialCountTest,
		collection: collectionTest || collection,
		displayLimit,
		modelOfferBackgroundImage
	};
};

export { useURLPrefs };
