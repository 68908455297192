import { aR as ASPECT_RATIO, perpetualOptions } from '../global-constants';

/**
 * Converts a comma-separated list to an array
 * @param {string} url - A string containing a comma-separated list
 */
export const csvToArray = (str) => {
	let result = [];

	if (typeof str === 'string') {
		// Trim values and remove blank entries
		result = str
			.split(',')
			.map((s) => s.trim())
			.filter((s) => s);
	}
	return result;
};

/**
 * Converts a string to a hash which can be used for 'key' values
 * Returns a hash string
 * @param {string} url - A non-empty string
 */
/* eslint-disable no-bitwise, no-plusplus */
export const stringToHash = (str) => {
	let hash = 0;
	let i = 0;
	const len = str.length;

	while (i < len) {
		hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
	}
	return hash.toString();
};

/**
 * Determines if a URL contains a specific URL parameter
 * Returns a boolean that checks for param within the `url` string
 * @param {string} url - A URL string value
 * @param {string} param - A string value to be checked within the `url` param string
 */
export const hasUrlParam = (url = '', param = '') => {
	const paramName = param.split('=');
	const paramRegex = new RegExp(`.*[?&]${paramName[0]}=.*`);
	return paramRegex.test(url);
};

/**
 * Sets a URL parameter based on existence of a `?` within the `url` param string
 * Returns a string with `url`, `param` and appropriate separator if `url` doesn't
 * already contain that URL param
 * @param {string} url - A URL string value
 * @param {string} param - A string value to be added as a URL parameter
 */
export const setUrlParam = (url = '', param = '') => {
	if (hasUrlParam(url, param) || !param) {
		return url;
	}
	const separator = url && url.includes('?') ? '&' : '?';
	return `${url}${separator}${param}`;
};

/**
 * Returns a query string value given the query string's field.
 * Will return null if there is no value for the field or if the query string field is not in the url.
 * If there are 2 of the same query string fields it will return the first.
 * @param {string} url - A URL string value
 * @param {string} queryStringField - Query string field
 * @returns {string|null} value of query string field or null
 */
export const getQueryStringValue = (url = '', queryStringField = '') => {
	if (hasUrlParam(url, queryStringField)) {
		const reg = new RegExp(`${queryStringField}=([^|&]+)`);
		if (url.match(reg)) {
			return url.match(reg)[1];
		}
	}
	return null;
};

/**
 * Returns a key value string, the value is encode to be used in a query param
 * @param {string} key - Query param key
 * @param {string} value - Query param value
 * @returns {string} a key value string
 */
export const queryStringPair = (key = '', value = '') => {
	let pair = null;

	// Ensure parameters are not empty, but allow the value to be zero
	if (key && (value || value === 0)) {
		const keyClean = key.trim();

		if (keyClean) {
			const valueClean = encodeURIComponent(value).trim();
			pair = `${keyClean}=${valueClean}&`;
		}
	}
	return pair;
};

/**
 * Builds query string,
 * e.g. promoRO.accountId=pcllgroupdemo&promoRO.locale=en_US&promoRO.autoGeneratedSpecialLimit=10&
 * @param {object} parameters - Query param key
 * @returns {string} a query string
 */
export const parametersToQueryString = (parameters) => {
	return Object.keys(parameters).reduce(
		(acc, val) =>
			acc +
			Object.keys(parameters[val]).reduce((acc2, val2) => {
				let result;
				const key = `${val}.${val2}`;
				const value = parameters[val][val2];

				// An array of values should be turned into multiple parameters, e.g.
				//   promoRO.types=event&promoRO.types=incentive
				if (Array.isArray(value)) {
					result = value.reduce(
						(acc3, val3) => `${acc3}${queryStringPair(key, val3)}`,
						''
					);
				} else {
					result = queryStringPair(key, value);
				}
				return result ? `${acc2}${result}` : acc2;
			}, ''),
		''
	);
};

/**
 * Method for tracking impressions for coupon listings
 */
export const buildImpObject = (promos) => {
	return promos.reduce((acc, promo) => {
		const newObj = {
			specialId: promo.id,
			specialCategory: promo.type.toLowerCase(),
			specialAutoGen: promo.autoGenerated,
			customAsset:
				promo.customAssets &&
				Object.getOwnPropertyNames(promo.customAssets).length > 0
		};
		acc.push(newObj);
		return acc;
	}, []);
};

/**
 *
 * Method for grouping object by key
 */
export const groupObjArrByKey = (arr, keyName) => {
	const obj = {};
	arr.forEach((v) => {
		if (!(v[keyName] in obj)) {
			obj[v[keyName]] = [];
		}
		obj[v.type].push(v);
	});
	return obj;
};

export const formatPerpetualDates = (
	startDateDisplay,
	endDateDisplay,
	labels
) => {
	let output = null;

	switch (endDateDisplay) {
		case perpetualOptions.NO_SHOW:
			if (startDateDisplay === perpetualOptions.NO_SHOW_ON_SPECIAL) {
				output = (
					`<p tabIndex="1">${labels.get('NO_EXPIRATION_DATE')}</p>`
				);
			} else {
				output = (
					`<p tabIndex="1">
						${labels
							.get('START_DATE_END_DATE_NO_EXPIRATION')
							.replace('START_DATE', startDateDisplay)}
					</p>`
				);
			}
			break;
		case perpetualOptions.NO_SHOW_ON_SPECIAL:
			if (startDateDisplay === perpetualOptions.NO_SHOW_ON_SPECIAL) {
				output = '<p />';
			} else {
				output = (
					`<p tabIndex="1">
						${labels.get('OFFER_VALID_ON')} ${startDateDisplay}
					</p>`
				);
			}
			break;
		default:
			if (startDateDisplay === perpetualOptions.NO_SHOW_ON_SPECIAL) {
				output = (
					`<p tabIndex="1">
						${labels.get('EXPIRES_WS_SPECIALS')} ${endDateDisplay}
					</p>`
				);
			} else {
				output = (
					`<p tabIndex="1">
						${labels
							.get('OFFER_ONLY_VALID_THROUGH_DATES')
							.replace('START_DATE', startDateDisplay)
							.replace('END_DATE', endDateDisplay)}
					</p>`
				);
			}
	}
	return output;
};

export const hasIosChrome = (userAgent) => userAgent.indexOf('CriOS') !== -1;

export const loadCssBundleByAspectRatio = async (aspectRatio, collection) => {
	let cssBundleName = aspectRatio;
	// This bundle is shared between Mobile4x3 and Mobile4x3-slideshow/Mobile4x3-coupon
	if([ASPECT_RATIO.MOBILE_4X3, ASPECT_RATIO.MOBILE_4X3_COUPON, ASPECT_RATIO.MOBILE_4X3_SLIDESHOW].includes(aspectRatio)) {
		cssBundleName = 'Mobile4x3';
	}
	await import(`../templates/${collection?.toLowerCase()}/sass/${cssBundleName}`);
}

export const autoGeneratedSpecialLimitForPromoRO = (
	autoGeneratedSpecialLimit
) => {
	let autoGeneratedSpecialLimitValue =
		autoGeneratedSpecialLimit.toLowerCase() === 'No Limit'.toLowerCase()
			? '30'
			: autoGeneratedSpecialLimit;

	// Resetting autoGeneratedSpecialLimit pref back to the default if the value is false
	if (autoGeneratedSpecialLimit === 'false') {
		autoGeneratedSpecialLimitValue = '3';
	}

	return autoGeneratedSpecialLimitValue;
};
