import { a } from '../global-constants';

const vehicleInfo = (state = false, { type, payload }) => {
	switch (type) {
		case a.SET_VEHICLE_ID: {
			return payload;
		}
		default:
			return state;
	}
};

export default vehicleInfo;
