import React from 'react';
import { hysteriFetch } from 'hysterics';
import { useSelector } from 'react-redux';
import { useLabels, useRequestData, usePrefs } from 'wsm-common-data';
import { getDiscountDisplay } from '../utilities/general';
import { trackEvent } from '../utilities/tracking';
import { appleWalletComponentProps } from '../utilities/commonPropTypes';

const AppleWalletButton = ({
	title,
	description,
	disclaimer,
	discountValue,
	discountType,
	endDateDisplay,
	tabIndexBase,
	id,
	promoDetails,
	promoOemId,
	incentive = {},
	applicableVehicle = ''
}) => {
	const requestUrl = '/api/widget/ws-specials/pass';
	const fetchApplePass = hysteriFetch('ws-specials-apple-pass');
	const accountName = useSelector((state) => state.account.name);
	const labels = useLabels();
	const { reduceDollarSignSize } = usePrefs();
	const { widgetName, windowId, locale } = useRequestData();
	let discountDisplay = getDiscountDisplay(
		locale,
		discountType,
		discountValue,
		labels,
		reduceDollarSignSize
	);
	let discountLabel =
		discountDisplay && discountType === 'FIXED'
			? labels.get('PRICE')
			: labels.get('SPECIALS_DISCOUNT');

	// Re-mapping data when incentive is available
	if (incentive !== null && Object.keys(incentive).length) {
		/* eslint-disable no-param-reassign */
		const { shortTitle, offerDetails, expirationDate } = incentive;
		title = shortTitle;
		discountDisplay = applicableVehicle;
		description = offerDetails;
		endDateDisplay = expirationDate;
		disclaimer = incentive.disclaimer;
		discountLabel = '';
	}

	const handleClick = () => {
		trackEvent({
			widgetName,
			windowId,
			element: 'Add to Apple Wallet Button',
			result: 'Offer added to Apple Wallet',
			oemId: promoOemId
		});

		fetchApplePass(requestUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				parameters: {
					title,
					description,
					disclaimer,
					discountDisplay: discountDisplay || '',
					accountName,
					endDateDisplay,
					id,
					promoDetails,
					titleLabel: labels.get('TITLE'),
					offerDetails: labels.get('OFFER_DETAILS'),
					disclaimerLabel: labels.get('DISCLAIMERS'),
					descriptionLabel: labels.get('DESCRIPTION'),
					discountLabel: discountDisplay ? discountLabel : ''
				}
			})
		})
			.then((response) => {
				const data = Uint8Array.from(response.body.data);
				const content = new Blob([data.buffer], {
					type: 'application/vnd.apple.pkpass'
				});
				const encodedUri = window.URL.createObjectURL(content);
				const link = document.createElement('a');

				link.setAttribute('href', encodedUri);
				link.setAttribute('download', 'promo.pkpass');

				link.click();
			})
			.catch((e) => {
				throw new Error(`Apple Pass fetch error: ${e}`);
			});
	};

	const button = (
		<button
			type="button"
			tabIndex={tabIndexBase + 6}
			onClick={handleClick}
			className="btn btn-link d-block p-0 mb-4"
		>
			<img
				src={`https://images.dealer.com/apple/pass/templates/${locale}/apple-logo.svg`}
				alt="Apple Wallet Logo"
			/>
		</button>
	);

	return button;
};

AppleWalletButton.propTypes = appleWalletComponentProps;

export default AppleWalletButton;
