import {
	aR
} from '../global-constants';

// This happens on Mobile6x1, Mobile4x3SlideShow
export const fakeHeightOfCarousel = (
	enableCarousel,
	displayLimit,
	aspectRatio
) => {
	let fakeCarouselHeight = '0px';
	if (enableCarousel === 'true' && displayLimit !== '1') {
		switch (aspectRatio) {
			case aR.MOBILE_6X1:
			case aR.MOBILE_4X3_SLIDESHOW:
				fakeCarouselHeight = '48px';
				break;
			default:
				fakeCarouselHeight = '0px';
				break;
		}
	}
	return `calc(100% + ${fakeCarouselHeight})`;
};