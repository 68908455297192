import { setUrlParam } from './utility';
import { getPlacement } from './aspect';
import { DOWNSIZE_VALUES } from '../global-constants';

// Image breakpoints used by Akamai for resizing images using `impolicy=downsize_bkpt`
// Available on pictures.dealer.com, images.dealer.com, and static.dealer.com
export const IMAGE_BREAKPOINTS = [52, 520, 768, 1200, 1600, 2000];

/**
 * Return the breakpoint that's just larger than the width
 * If width is greater than the largest breakpoint, return the largest breakpoint
 * @param {number} width - A numeric width
 */
export const getImageBreakpoint = (width = 0) => {
	return IMAGE_BREAKPOINTS.reduce((acc, val) => (width > acc ? val : acc));
};

/**
 * Determines if a specific URL matches conditional checks
 * Returns a boolean that checks for specific Dealer.com image URLs for which
 *   Akamai resizing can be applied
 * @param {string} url - A URL string value
 */
export const isDealerImage = (url) => {
	const domainRegex =
		/(.*\/\/.*)?(pictures|images|static)(\.)([^.]+\.)?dealer\.com(.*)/;
	const rootRegex = /\/static\/assets\//;
	return domainRegex.test(url) || rootRegex.test(url);
};

/**
 * Return an image URL with Akamai optimization query string parameters
 * @param {string} imageUrl - A URL string value
 * @param {number} width - A numeric width
 */
export const optimizeImage = (imageSrc, imageWidth = 0) => {
	// TODO: We don't have any breakpoints between 52 and 520px
	//       For mobile, we may want to consider `impolicy=downsize` w/ our own breakpoints
	const optimized = {
		src: '',
		width: 0
	};

	if (imageWidth > 0) {
		optimized.src = imageSrc;
		if (isDealerImage(imageSrc)) {
			optimized.width = getImageBreakpoint(imageWidth);
			optimized.src = setUrlParam(
				imageSrc,
				`impolicy=downsize_bkpt&w=${optimized.width}`
			);
		}
	}

	return optimized;
};

// This happens on vehicleBackgroundImage and modelOfferBackgroundImage
export const optimizeDefaultDownsize = (imageSrc, deviceType, aspectRatio) => {
	const defaultDownsize = {
		src: '',
		width: 0
	};

	if (!deviceType) {
		return defaultDownsize;
	}

	const placement = getPlacement(aspectRatio);
	// Get downsize image
	const imageWidth = DOWNSIZE_VALUES[placement][deviceType];

	defaultDownsize.src = imageSrc;
	if (isDealerImage(imageSrc)) {
		defaultDownsize.width = imageWidth;
		defaultDownsize.src = setUrlParam(
			imageSrc,
			`impolicy=downsize_bkpt&w=${imageWidth}`
		);
	}

	return defaultDownsize;
};
