import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';

export const useIntersectionObserver = (intersectionRatio, callback) => {
	const ref = useRef(null);
	const hasdata = useSelector((state) => state.hasdata);
	useEffect(() => {
		if (
			isBrowser &&
			ref &&
			ref.current &&
			typeof IntersectionObserver !== 'undefined'
		) {
			const target = ref.current;
			const options = {
				threshold: intersectionRatio
			};
			const observer = new IntersectionObserver((entities) => {
				const [entity] = entities;
				if (entity.intersectionRatio >= intersectionRatio) {
					callback();
					observer.disconnect();
				}
			}, options);
			observer.observe(target);
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [hasdata, intersectionRatio]);

	return [ref];
};
